import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { env } from 'lib/env';
import enFrontitude from 'locales/frontitude.json';
import esFrontitude from 'locales/frontitude-es.json';

export const defaultNS = 'common';
export const resources = {
  en: {
    frontitude: enFrontitude,
  },
  es: {
    frontitude: esFrontitude,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['frontitude'],
    defaultNS,
    resources,
    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'lng',
    },
    fallbackLng: 'en',
    debug: env.VITE_ENVIRONMENT === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
