import { Heading, PageLayout, PageLayoutContent, Paragraph } from 'components';
import { useTranslation } from 'react-i18next';
import {
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import { ButtonLink } from 'components/forms/ButtonLink';

type PediatricsIneligibleProps = {
  screenSourceFlow: AnalyticsSourceFlow;
};

export function PediatricsIneligible(props: PediatricsIneligibleProps) {
  const { screenSourceFlow } = props;
  const screenName: AnalyticsScreenName = 'PediatricsIneligibleScreen';
  const { t } = useTranslation('frontitude');

  return (
    <PageLayout
      progress={7 / 7}
      screenName={screenName}
      screenSourceFlow={screenSourceFlow}
    >
      <PageLayoutContent centered>
        <Heading dataTestId="pediatrics_unsupported_title">
          {t('pediatrics_unsupported_title')}
        </Heading>
        <Paragraph>{t('pediatrics_unsupported_body')}</Paragraph>

        <br />

        <ButtonLink type="primary" href="mailto:support@galileohealth.com">
          {t('cta_get_help')}
        </ButtonLink>
      </PageLayoutContent>
    </PageLayout>
  );
}
