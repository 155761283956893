import { API, flaskApiClient } from 'constants/apiEndpoints';
import { format } from 'date-fns';
import { z } from 'zod';
import { client } from 'constants/apiV2Clients';
import {
  CreateCoverageResponse,
  CreateEligibilityCheckRequestResponse,
  EligibilityCheckRequestResult,
  InsuranceCarrier as NewInsuranceCarrier,
  Patient,
} from '@galileo/core-api-client';
import { FLASK_API_GENDER } from './constants';
import { getCoreApiSexAssignedAtBirth, STATES } from './util.ts';

const UpdateAccountParamsSchema = z.object({
  dateOfBirth: z.date().optional(),
  street: z.string().optional(),
  city: z.string().optional(),
  zip: z.string().max(5).optional(),
  state: z.enum(STATES).optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  preferredName: z.string().optional(),
  gender: z.enum(FLASK_API_GENDER).optional(),
  genderDisplay: z.string().optional(),
  genderIdentity: z.string().optional(),
  sexAssignedAtBirth: z.string().optional(),
  registrationPageSource: z.string().optional(),
});
type UpdateAccountParams = z.infer<typeof UpdateAccountParamsSchema>;

const formPayload = (params: UpdateAccountParams) => {
  // Function to filter out undefined values
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterUndefined = (obj: any) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined)
    );
  };

  // Initialize the payload object
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const payload: any = {};
  const keyFormatter = (key: string) => {
    if (key === 'dateOfBirth') return 'date_of_birth';
    if (key === 'firstName') return 'first_name';
    if (key === 'lastName') return 'last_name';
    if (key === 'preferredName') return 'preferred_name';
    if (key === 'genderIdentity') return 'gender_identity';
    if (key === 'sexAssignedAtBirth') return 'sex_assigned_at_birth';
    return key;
  };
  // Iterate over params and add to payload if defined
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      switch (key) {
        case 'dateOfBirth':
          payload.date_of_birth = format(new Date(value), 'yyyy-MM-dd');
          break;
        case 'street':
          payload.address = payload.address || {};
          payload.address.lines = [value];
          break;
        case 'city':
          payload.address = payload.address || {};
          payload.address.city = value;
          break;
        case 'state':
          payload.address = payload.address || {};
          payload.address.state = value;
          break;
        case 'zip':
          payload.address = payload.address || {};
          payload.address.zip_code = value;
          break;
        case 'sexAssignedAtBirth':
          payload.sex_assigned_at_birth = getCoreApiSexAssignedAtBirth(
            value as string | undefined
          );
          break;
        default:
          payload[keyFormatter(key)] = value;
      }
    }
  });
  // Filter out undefined values from nested objects
  if (payload.address) {
    payload.address = filterUndefined(payload.address);
  }
  return payload;
};

export async function updateRegistrationSourceService(
  patientId: string,
  registrationPageSource: string
) {
  await client.patientsApi.patientsProfileUpdate(patientId, {
    registration_page_source: registrationPageSource,
  });
}

export async function updatePatientProfileService(
  patientId: string,
  account: Partial<UpdateAccountParams>
) {
  const params = UpdateAccountParamsSchema.parse(account);
  const payload = formPayload(params);

  await client.patientsApi.patientsProfileUpdate(patientId, payload);
}

export async function searchPatientService(
  accountId: string
): Promise<Patient[]> {
  const response = await client.patientsApi.patientsProfileList(accountId);
  return response.data;
}

type RequestPhoneConfirmationParams = {
  accountId: string;
  countryCode: string;
  phoneNumber: string;
};

export async function requestPhoneConfirmationService(
  params: RequestPhoneConfirmationParams
) {
  const { accountId, countryCode, phoneNumber } = params;
  await flaskApiClient.post(API.accountPhoneVerificationRequest(accountId), {
    mobile_country_code: countryCode,
    mobile_phone_number: phoneNumber,
  });
}

type ConfirmPhoneParams = {
  accountId: string;
  confirmation: string;
};

export async function confirmPhoneService(params: ConfirmPhoneParams) {
  const { accountId, confirmation } = params;
  await flaskApiClient.post(
    API.accountPhoneVerificationConfirmation(accountId),
    {
      mobile_phone_number_verification_code: confirmation,
    }
  );
}

type EmployeeSponsorshipParams = {
  accountId: string;
  accessCode: string;
};

export async function confirmEmployeeSponsorshipService(
  params: EmployeeSponsorshipParams
) {
  const { accountId, accessCode } = params;
  const response = await flaskApiClient.post(
    API.accountEmployeeSponsorshipVerification(accountId),
    {
      access_code: accessCode,
    }
  );
  return response.data.data[0];
}

export async function getPayorsCarriersList(): Promise<NewInsuranceCarrier[]> {
  const response = await client.payorsApi.payorsCarriersList();
  return response.data;
}

type EligibilityClaimParams = {
  accountId: string;
  groupId: string;
  memberId: string;
};

type EligibilityClaimResponse = {
  data: {
    eligible: boolean;
    refund: number;
    coupon_claim_method: string;
  }[];
};

export async function claimEligibilityService(params: EligibilityClaimParams) {
  const { accountId, groupId, memberId } = params;
  const response = await flaskApiClient.post<EligibilityClaimResponse>(
    API.accountEligibilityClaim(accountId, groupId),
    { member_id: memberId }
  );
  return response.data.data[0];
}

type CheckPatientCoverageParams = {
  patientId: string;
  insuranceCarrierId: string;
  memberId: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
};

export async function checkPatientCoverage(
  params: CheckPatientCoverageParams
): Promise<CreateCoverageResponse> {
  const {
    patientId,
    insuranceCarrierId,
    memberId,
    firstName,
    lastName,
    dateOfBirth,
  } = params;
  const response =
    await client.financialApi.financialSupportPatientsCoveragesCreate(
      patientId,
      {
        insurance_carrier_id: insuranceCarrierId,
        member_id: memberId,
        first_name: firstName,
        last_name: lastName,
        date_of_birth: dateOfBirth,
      }
    );
  return response.data;
}

type CreateCheckPatientEligiblityRequestParams = {
  patientId: string;
  insuranceCarrierId: string;
  memberId: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
};

export async function createCheckPatientEligibilityRequest(
  params: CreateCheckPatientEligiblityRequestParams
): Promise<CreateEligibilityCheckRequestResponse> {
  const {
    patientId,
    insuranceCarrierId,
    memberId,
    firstName,
    lastName,
    dateOfBirth,
  } = params;
  const response =
    await client.financialApi.financialSupportPatientsEligibilityCheckRequestsCreate(
      patientId,
      {
        insurance_carrier_id: insuranceCarrierId,
        member_id: memberId,
        first_name: firstName,
        last_name: lastName,
        date_of_birth: dateOfBirth,
      }
    );
  return response.data;
}

export async function retrieveCheckPatientPatientEligiblityRequest(
  eligibilityCheckRequestId: string,
  patientId: string
): Promise<EligibilityCheckRequestResult> {
  const response =
    await client.financialApi.financialSupportPatientsEligibilityCheckRequestsRetrieve(
      eligibilityCheckRequestId,
      patientId
    );
  return response.data;
}

export async function sendDownloadLinkService(
  countryCode: string,
  phoneNumber: string,
  language: string
) {
  // Remove leading 0s from country code as legacy flask code allows for it
  const formattedCountryCode = countryCode.replace(/^0{1,2}1$/, '1');
  const toNumber = `+${formattedCountryCode}${phoneNumber}`;
  await flaskApiClient.post(API.sendDownloadLink(), {
    to_number: toNumber,
    message_key: 'download',
    source: 'healthweb',
    language,
  });
}

type MedicareEnrollmentDetails = {
  is_medicare_enrolled: boolean;
  is_medicaid_enrolled: boolean;
};

export async function toggleMedicareMedicaidStatus(
  accountId: string,
  data: MedicareEnrollmentDetails
) {
  await flaskApiClient.put(API.medicareMedicaidStatus(accountId), data);
}
