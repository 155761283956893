import { CircleSubmit, Radio, TextInput } from 'components/forms';
import { z } from 'zod';
import { Heading, IntakeKicker, SubHeading } from 'components';
import { useForm, Control, UseFormWatch } from 'react-hook-form';
import { Space } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { useMutation } from '@tanstack/react-query';
import { updatePatientProfileService } from 'features/NewUserOnboarding/services';
import {
  IntakesLayout,
  IntakesLayoutContent,
  IntakesLayoutFooter,
} from 'components/pageLayout/IntakesLayout';
import {
  Mixpanel,
  MixpanelEvents,
  MixpanelSourceFlows,
} from 'helpers/mixpanel';
import {
  PatientAnalytics,
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import {
  GENDER_IDENTITY,
  GenderIdentity,
  SexAssignedAtBirth,
  SEX_ASSIGNED_AT_BIRTH,
} from '../NewUserOnboarding/constants';
import { useNewUserOnboardingStore } from '../NewUserOnboarding/store';

const schema = z.object({
  sexAssignedAtBirth: z.enum(SEX_ASSIGNED_AT_BIRTH),
  genderIdentity: z.enum(GENDER_IDENTITY).optional(),
  otherGenderIdentity: z.string().optional(),
});

type Schema = z.infer<typeof schema>;

type GenderIdentityProps = {
  control: Control<Schema>;
  watch: UseFormWatch<Schema>;
};

const GenderIdentityControl = (props: GenderIdentityProps) => {
  const { t } = useTranslation('frontitude');
  const { control, watch } = props;
  const genderIdentityOptions: {
    key: string;
    label: string;
    value: GenderIdentity;
  }[] = [
    {
      key: '0',
      label: t('genderidentity_option_female'),
      value: 'Female/Woman',
    },
    {
      key: '1',
      label: t('genderidentity_option_male'),
      value: 'Male/Man',
    },
    {
      key: '2',
      label: t('genderidentity_option_transfemale'),
      value: 'Trans Female/Trans Woman',
    },
    {
      key: '3',
      label: t('genderidentity_option_transmale'),
      value: 'Trans Male/Trans Man',
    },
    {
      key: '4',
      label: t('genderidentity_option_genderqueer'),
      value: 'Genderqueer',
    },
    {
      key: '5',
      label: t('genderidentity_option_nonbinary'),
      value: 'Nonbinary',
    },
    {
      key: '6',
      label: t('genderidentity_option_twospirit'),
      value: 'Two-Spirit',
    },
    {
      key: '7',
      label: t('genderidentity_option_agender'),
      value: 'Agender',
    },
    {
      key: '8',
      label: t('genderidentity_option_prefer_not'),
      value: 'Prefer Not to Say',
    },
    {
      key: '9',
      label: t('other'),
      value: 'Other',
    },
  ];

  const genderIdentity = watch('genderIdentity');

  return (
    <div className="w-full">
      <Space direction="vertical">
        <div className="text-color-charcoal-12 text-base">
          {t('gender_identity_question')}
        </div>
        <Radio
          control={control}
          name="genderIdentity"
          options={genderIdentityOptions}
          direction="horizontal"
          style={{ borderRadius: '50px', height: '44px' }}
          optionType="button"
        />
        {genderIdentity === 'Other' && (
          <TextInput
            control={control}
            name="otherGenderIdentity"
            label={t('other')}
            type="text"
          />
        )}
      </Space>
    </div>
  );
};

type SexAssignedAtBirthProps = {
  control: Control<Schema>;
};

const SexAssignedAtBirthControl = (props: SexAssignedAtBirthProps) => {
  const { t } = useTranslation('frontitude');
  const { control } = props;

  const sexAssignedAtBirthOptions: {
    key: string;
    label: string;
    value: SexAssignedAtBirth;
  }[] = [
    {
      key: '0',
      label: t('saab_option_male'),
      value: 'male',
    },
    {
      key: '1',
      label: t('saab_option_female'),
      value: 'female',
    },
    {
      key: '2',
      label: t('saab_option_intersex'),
      value: 'intersex',
    },
  ];

  return (
    <div className="mb-4">
      <div className="text-base">{t('saab_question')}</div>
      <div className="mt-2 ">
        <Radio
          control={control}
          name="sexAssignedAtBirth"
          options={sexAssignedAtBirthOptions}
          direction="vertical"
        />
      </div>
    </div>
  );
};

export function BiologicalSexesForm() {
  const screenName: AnalyticsScreenName = 'SaabScreen';
  const screenSourceFlow: AnalyticsSourceFlow = 'intakes';
  const { t } = useTranslation('frontitude');
  const [setBiologicalSexes, storedGender] = useNewUserOnboardingStore(
    state => [
      state.setBiologicalSexes,
      {
        sexAssignedAtBirth: state.sexAssignedAtBirth,
        genderIdentity: state.genderIdentity,
        otherGenderIdentity: state.otherGenderIdentity,
      },
    ]
  );

  const [showGenderIdentity, setShowGenderIdentity] = useState(
    Boolean(storedGender.genderIdentity || storedGender.otherGenderIdentity)
  );

  const { patientId, refetchAccount } = usePatient();
  const { control, handleSubmit, formState, watch, setError } = useForm<Schema>(
    {
      resolver: zodResolver(schema),
      defaultValues: {
        sexAssignedAtBirth: storedGender.sexAssignedAtBirth,
        genderIdentity: storedGender.genderIdentity,
        otherGenderIdentity: storedGender.otherGenderIdentity,
      },
    }
  );

  const query = useMutation({
    mutationFn: (data: Schema) => {
      setBiologicalSexes(data);
      return updatePatientProfileService(patientId, {
        ...data,
      });
    },
    onSuccess: () => {
      Mixpanel.track(MixpanelEvents.SAABSCREEN_SUBMITTED, {
        source_flow: MixpanelSourceFlows.PRECARE,
      });
      PatientAnalytics.track(screenName, 'Submitted', {
        source_flow: screenSourceFlow,
      });
      refetchAccount();
    },
    onError: () => {
      setError('root.serverError', {
        message: t('demographics.unable_to_update_information_error'),
      });
    },
  });

  return (
    <form
      data-testid="saabForm"
      onSubmit={handleSubmit(data => query.mutate(data))}
    >
      <IntakesLayout step="search" screenName={screenName}>
        <IntakesLayoutContent>
          <IntakeKicker>{t('preintake_header')}</IntakeKicker>

          <Heading dataTestId="saabHeader">{t('saab_header')}</Heading>
          <SubHeading
            dataTestId="saabSubheader"
            className="mb-2 text-start text-charcoal-8"
          >
            {t('saab_subheader')}
          </SubHeading>

          <div>
            <Space direction="vertical" className="w-full">
              <SexAssignedAtBirthControl control={control} />
            </Space>
            {!showGenderIdentity && (
              <button
                data-testid="genderIdentityButton"
                onClick={() => setShowGenderIdentity(true)}
                className="mb-4 border-none p-0 text-denim-10 outline-none"
                type="button"
                aria-label="Add Gender Identity"
              >
                {t('gender_identity_question')}
              </button>
            )}
            {showGenderIdentity && (
              <GenderIdentityControl control={control} watch={watch} />
            )}
          </div>
        </IntakesLayoutContent>

        <IntakesLayoutFooter>
          <CircleSubmit valid={formState.isValid} loading={query.isLoading} />
        </IntakesLayoutFooter>
      </IntakesLayout>
    </form>
  );
}
