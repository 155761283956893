import { CheckOutlined } from '@ant-design/icons';
import { Answer } from '@galileo/core-api-client';
import { cn } from 'lib/util';
import { ChangeEvent } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type MultiSelectOtherProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  answer: Answer;
  required: boolean;
  freeTextResponse?: string;
  updateFreeTextResponse: (value: string) => void;
};

export function MultiSelectOther<TFieldValues extends FieldValues>(
  props: MultiSelectOtherProps<TFieldValues>
) {
  const { t } = useTranslation('frontitude');
  const {
    control,
    name,
    answer,
    required,
    freeTextResponse,
    updateFreeTextResponse,
  } = props;
  const { field } = useController({ name, control, rules: { required } });

  const isSelected = field.value.includes(answer.id);

  const handleChange = () => {
    // NOTE: react-hook-form "should" just handle checkbox arrays, but it's not, so fake it
    if (isSelected) {
      field.onChange(field.value.filter((v: string) => v !== answer.id));
    } else {
      field.onChange([...field.value, answer.id]);
    }
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Force selection of the checkbox if there is content
    if (e.target.value.length > 0) {
      field.onChange([...field.value, answer.id]);
    } else {
      field.onChange(field.value.filter((v: string) => v !== answer.id));
    }

    updateFreeTextResponse(e.target.value);
  };

  return (
    <div>
      <input
        type="checkbox"
        className="appearance-none"
        id={answer.id}
        name={name}
        value={answer.id}
        checked={isSelected}
        onChange={handleChange}
      />
      <label
        htmlFor={answer.id}
        className={cn(
          'grid cursor-pointer grid-cols-[min-content_1fr] items-center gap-3 rounded-md border border-charcoal-2 p-4 transition-all duration-150 ease-in-out hover:border-charcoal-4',
          isSelected && 'border-earth-100 bg-earth-100 hover:border-earth-100'
        )}
      >
        <div>
          {isSelected ? (
            <div className="relative grid h-5 w-5 place-items-center rounded bg-space-12">
              <CheckOutlined className="h-3 w-3 text-white" />
            </div>
          ) : (
            <div className="relative h-5 w-5 rounded border border-charcoal-2" />
          )}
        </div>
        <div>
          <input
            type="text"
            name="free_text"
            value={freeTextResponse}
            onChange={handleTextChange}
            placeholder={t('other')}
            className={cn('w-full outline-none', isSelected && 'bg-earth-100')}
          />
        </div>
      </label>
    </div>
  );
}
