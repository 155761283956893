import { useMask } from '@react-input/mask';
import { Input, InputRef } from 'antd';
import { cn } from 'lib/util';
import React, { useRef } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

export type MaskOptions = {
  mask: string;
  replacement: Record<string, RegExp>;
  showMask?: boolean;
};

export type MaskedTextInputProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  type?: 'text' | 'password' | 'email' | 'tel' | 'date' | 'number';
  required?: boolean;
  label: string;
  autoComplete?: string;
  maxLength?: number;
  disabled?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
  maskOptions: MaskOptions;
};

export function MaskedInput<TFieldValues extends FieldValues>(
  props: MaskedTextInputProps<TFieldValues>
) {
  const { control, name, label, maxLength, disabled, placeholder, style } =
    props;

  const { mask, replacement, showMask } = props.maskOptions;
  const type = props.type ?? 'text';
  const required = props.required ?? false;
  const { field, fieldState } = useController({ name, control });
  const hasContent = field.value !== '' && field.value !== undefined;
  const hasError = fieldState.error !== undefined;

  const bindOnChange: BindChangeEventHandler<HTMLInputElement> =
    handler => e => {
      if (type === 'number') {
        const value = Number(e.target.value);
        handler(value);
      } else {
        handler(e.target.value);
      }
    };
  const maskRef = useMask({
    mask,
    replacement,
    showMask,
  });

  const inputRef = useRef<InputRef>(null);
  if (inputRef?.current?.input && maskRef) {
    maskRef.current = inputRef.current.input;
  }

  return (
    <fieldset className="relative">
      <Input
        data-testid={name}
        id={name}
        name={name}
        className={cn(
          'peer z-0 mb-4 h-14 w-full rounded-lg pb-2 pt-6 text-lg',
          hasError && 'border-tomato-12'
        )}
        onBlur={field.onBlur}
        onChange={bindOnChange(field.onChange)}
        value={field.value}
        type={type === 'number' ? 'text' : type}
        size="large"
        autoComplete={props.autoComplete}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={placeholder}
        ref={inputRef}
        style={style}
      />
      <label
        className={cn(
          'absolute left-3 z-10 text-lg text-charcoal-6 transition-all duration-100 peer-focus:top-2 peer-focus:text-xs',
          hasContent ? 'top-2 text-xs' : 'top-3',
          hasError && 'text-tomato-12'
        )}
        htmlFor={name}
      >
        {label}
        {required && <span>*</span>}
      </label>
      {hasError && (
        <div className="-mt-4 text-tomato-12">{fieldState.error?.message}</div>
      )}
    </fieldset>
  );
}
