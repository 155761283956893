import {
  CircleBack,
  CircleSubmit,
  TextInput,
  MaskedInput,
} from 'components/forms';
import { z } from 'zod';
import {
  Heading,
  PageLayout,
  PageLayoutContent,
  PageLayoutFooter,
  SubHeading,
} from 'components';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import {
  PatientAnalytics,
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import { useMutation } from '@tanstack/react-query';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { isUnder18 } from 'helpers';
import { validateDateOfBirth } from 'helpers/date';
import { useNewUserOnboardingStore } from './store';
import { setNextStepFor, setPreviousStepFor } from './stepFlows/stepFlows';
import { State, STATES } from './util';
import { updatePatientProfileService } from './services';

const validateState = (state: string) => {
  return STATES.includes(state.toUpperCase());
};

const schema = z.object({
  dateOfBirth: z
    .string({ required_error: i18n.t('is required') })
    .refine(validateDateOfBirth, {
      message: i18n.t('demographics.date_of_birth.instruction', {
        ns: 'frontitude',
      }),
    }),
  state: z
    .string()
    .refine(validateState, {
      message: i18n.t('demographics.instruction.enter_state_abbreviation', {
        ns: 'frontitude',
      }),
    })
    .transform(state => state.toUpperCase() as State),
  zip: z
    .string({
      required_error: i18n.t('demographics.instruction.enter_zip_code', {
        ns: 'frontitude',
      }),
    })
    .trim()
    .regex(/^\d{5}$/, {
      message: i18n.t('demographics.instruction.enter_zip_code', {
        ns: 'frontitude',
      }),
    }),
});
type Schema = z.infer<typeof schema>;

export function DateOfBirthAndAddressForm() {
  const screenName: AnalyticsScreenName = 'DobAddressScreen';
  const screenSourceFlow: AnalyticsSourceFlow = 'ftux';
  const { t } = useTranslation('frontitude');
  const { patientId } = usePatient();
  const [accountDetails] = useNewUserOnboardingStore(state => [
    {
      firstName: state.firstName,
      lastName: state.lastName,
      preferredName: state.preferredName,
    },
  ]);
  const [setDOB, storedDateOfBirth, storedAddress, setPartialAddress] =
    useNewUserOnboardingStore(state => [
      state.setDOB,
      state.dateOfBirth,
      {
        state: state.state,
        zip: state.zip,
      },
      state.setPartialAddress,
    ]);

  const { control, handleSubmit, formState, watch, setError } = useForm<Schema>(
    {
      resolver: zodResolver(schema),
      defaultValues: {
        dateOfBirth: storedDateOfBirth
          ? format(storedDateOfBirth, 'MM/dd/yyyy')
          : '',
        state: storedAddress.state,
        zip: storedAddress.zip,
      },
      mode: 'onBlur',
    }
  );

  const onBack = () => {
    setPreviousStepFor('DATE_OF_BIRTH_ADDRESS_ENTRY');
  };

  const enteredDateOfBirth = watch('dateOfBirth');

  const query = useMutation({
    mutationFn: (data: Schema) => {
      setDOB({
        dateOfBirth: data.dateOfBirth,
      });
      setPartialAddress({
        state: data.state,
        zip: data.zip,
      });
      const payload = {
        ...data,
        dateOfBirth: new Date(data.dateOfBirth),
      };
      return updatePatientProfileService(patientId, {
        ...accountDetails,
        ...payload,
      });
    },
    onSuccess: () => {
      Mixpanel.track(MixpanelEvents.FTUX_SUBMITTED_DOB_ADDRESS_SCREEN);
      PatientAnalytics.track(screenName, 'Submitted', {
        source_flow: screenSourceFlow,
      });
      setNextStepFor('DATE_OF_BIRTH_ADDRESS_ENTRY');
    },
    onError: () => {
      PatientAnalytics.track(screenName, 'Errored', {
        source_flow: screenSourceFlow,
      });
      setError('root.serverError', {
        message: t('demographics.unable_to_update_information_error'),
      });
    },
  });
  return (
    <form onSubmit={handleSubmit(data => query.mutate(data))}>
      <PageLayout
        progress={2 / 7}
        screenName={screenName}
        screenSourceFlow={screenSourceFlow}
      >
        <PageLayoutContent>
          <div>
            <Heading
              dataTestId="demographicsHeader"
              className="mb-2 text-start text-charcoal-8"
            >
              {t('demographics_header')}
            </Heading>
            <SubHeading>{t('demographics_body')}</SubHeading>
            <MaskedInput
              control={control}
              required
              name="dateOfBirth"
              label={t('dob_label')}
              autoComplete="b-day"
              maskOptions={{
                mask: 'MM/DD/YYYY',
                replacement: { M: /\d/, D: /\d/, Y: /\d/ },
                showMask: true,
              }}
            />
            {isUnder18(enteredDateOfBirth) && (
              <div
                data-testid="pediatricWarning"
                className="mb-4 text-sm text-charcoal-12"
              >
                {t('pediatrics_under_eighteen_warning')}
              </div>
            )}

            <TextInput
              name="zip"
              label={t('zip_code_label')}
              control={control}
              maxLength={5}
              required
            />

            <TextInput
              name="state"
              label={t('state_label')}
              control={control}
              style={{ textTransform: 'uppercase' }}
              maxLength={2}
              required
            />
          </div>
        </PageLayoutContent>

        <PageLayoutFooter>
          <CircleBack onClick={onBack} />
          <CircleSubmit
            valid={formState.isValid}
            loading={formState.isSubmitting}
          />
        </PageLayoutFooter>
      </PageLayout>
    </form>
  );
}
