import { env } from 'lib/env';
import mixpanel, { Dict } from 'mixpanel-browser';
import { AnalyticsBrowser } from '@segment/analytics-next';

const isLocal = env.VITE_ENVIRONMENT === 'development';

const mixpanelClient = mixpanel.init(
  env.VITE_PATIENT_ANALYTICS_MIXPANEL_TOKEN,
  {
    persistence: 'localStorage',
  },
  'patient_analytics'
);

const segmentAnalytics = AnalyticsBrowser.load({
  writeKey: env.VITE_SEGMENT_WRITE_KEY,
});

const actions = {
  identify: (id: string) => {
    if (isLocal) return;
    mixpanelClient.identify(id);
  },
  track: (
    object: AnalyticsScreenName,
    action: AnalyticsAction,
    props?: Dict
  ) => {
    if (isLocal) return;
    const event = `${object}_${action}`;
    mixpanelClient.track(event, props);
  },
  people: {
    set: (props: Dict) => {
      if (isLocal) return;
      mixpanelClient.people.set(props);
    },
  },
  reset: () => {
    mixpanelClient.reset();
  },
};

export type AnalyticsScreenName =
  // screens
  | 'DobAddressScreen'
  | 'EmployeeSponsorshipScreen'
  | 'IdUploadScreen'
  | 'NameScreen'
  | 'InsuranceInfoScreen'
  | 'PhoneNumberConfirmationScreen'
  | 'PhoneNumberScreen'
  | 'SaabScreen'
  | 'Auth0UniversalLoginScreen'
  | 'GalileoEligibleScreen'
  | 'InsuranceConfirmationScreen'
  | 'InsuranceInfoScreen'
  | 'PediatricsIneligibleScreen'
  | 'RegistrationCompleteLockoutScreen'
  | 'AttachmentQuestionScreen'
  | 'FreeTextQuestionScreen'
  | 'IntakeScreen'
  | 'IntakesConfirmationScreen'
  | 'MultiSelectQuestionScreen'
  | 'SingleSelectQuestionScreen'
  | 'IntakesSearchScreen'
  | 'AddressScreen'
  // flows
  | 'PreAuth0Flow'
  | 'PostAuth0Flow'
  // misc
  | 'Logout';

export type AnalyticsAction =
  | 'Submitted'
  | 'Skipped'
  | 'Started'
  | 'Errored'
  | 'Viewed'
  | 'Completed';

export type AnalyticsSourceFlow = 'intakes' | 'ftux' | 'provider_onboarding';

export const PatientAnalytics = actions;

export async function setAnonymousId() {
  const analyticsUser = await segmentAnalytics.user();
  const segmentAnonymousId = analyticsUser.anonymousId();
  if (segmentAnalytics !== undefined && segmentAnonymousId !== undefined) {
    mixpanelClient.register({ $device_id: segmentAnonymousId });
  }
}

export async function getAnonymousId() {
  const analyticsUser = await segmentAnalytics.user();
  const anonymousId = analyticsUser.anonymousId();
  return anonymousId === null ? '' : anonymousId;
}

export async function segmentReset() {
  await segmentAnalytics.reset();
}
