/* eslint-disable jsx-a11y/img-redundant-alt */

import { CircleBack, CircleSubmit } from 'components/forms';
import { ChangeEventHandler, FormEventHandler, useRef } from 'react';
import { Heading, IntakeKicker, Paragraph } from 'components';
import { usePatient } from 'providers/OnboardedPatientProvider';
import {
  CameraOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  Mixpanel,
  MixpanelEvents,
  MixpanelSourceFlows,
} from 'helpers/mixpanel';
import {
  PatientAnalytics,
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import { usePhotoIdUpload } from 'features/pg-Intakes/usePhotoIdUpload';
import {
  IntakesLayout,
  IntakesLayoutContent,
  IntakesLayoutFooter,
} from 'components/pageLayout/IntakesLayout';
import { useIntakeStore } from 'features/pg-Intakes/stores';

type IdUploadFormProps = {
  onBack: () => void;
  onNext: () => void;
  canGoBack: boolean;
};

export function IdUploadForm(props: IdUploadFormProps) {
  const screenName: AnalyticsScreenName = 'IdUploadScreen';
  const screenSourceFlow: AnalyticsSourceFlow = 'intakes';
  const { onBack, onNext, canGoBack } = props;
  const { t } = useTranslation('frontitude');
  const uploadRef = useRef<HTMLInputElement>(null);

  const { accountId } = usePatient();
  const { isLoading, isPending, isValid, photoUrl, uploadPhoto, deletePhoto } =
    usePhotoIdUpload(accountId);
  const showSpinner = isLoading || isPending;
  const showPhoto = Boolean(photoUrl);
  const showUpload = !showSpinner && !showPhoto;
  const canDelete = !isLoading && !isPending && showPhoto;
  const canSubmit = !isLoading && !isPending && isValid;

  const [formName] = useIntakeStore(state => [state.formName]);
  const [lastQuestionId, setFirstQuestionId] = useIntakeStore(state => [
    state.lastQuestionId,
    state.setFirstQuestionId,
  ]);

  const onChange: ChangeEventHandler<HTMLInputElement> = event => {
    const file: File | undefined = event.target.files?.[0];
    if (!file) return;
    if (!file.type.includes('image')) return;
    uploadPhoto(file);
  };

  const onSubmit: FormEventHandler = e => {
    e.preventDefault();
    Mixpanel.track(MixpanelEvents.IDUPLOADSCREEN_SUBMITTED, {
      source_flow: MixpanelSourceFlows.PRECARE,
    });
    PatientAnalytics.track(screenName, 'Submitted', {
      source_flow: screenSourceFlow,
    });
    onNext();
  };

  const handleBack = () => {
    setFirstQuestionId(lastQuestionId);
    onBack();
  };

  return (
    <form onSubmit={onSubmit}>
      <IntakesLayout step="questions" screenName={screenName}>
        <IntakesLayoutContent>
          <IntakeKicker>{formName}</IntakeKicker>
          <Heading dataTestId="photoUploadHeader">
            {t('identity_verification_header')}
          </Heading>
          <Paragraph type="note">{t('identity_verification_body')}</Paragraph>

          <section className="relative grid aspect-photo max-h-[24rem] w-full max-w-[24rem] grid-cols-1 items-center justify-center rounded-lg border border-gray-2 bg-gray-1">
            {showSpinner && (
              <div className="absolute inset-0 z-10 grid place-items-center rounded-lg border border-gray-2 bg-gray-1 opacity-75">
                <LoadingOutlined className="text-3xl text-gray-6" />
              </div>
            )}

            {canDelete && (
              <button
                type="button"
                className="z-8 absolute right-2 top-2 h-8 w-8 border-0 bg-space-10 p-0 text-sm text-white hover:border-0"
                onClick={deletePhoto}
              >
                <CloseOutlined />
              </button>
            )}

            {showPhoto && (
              <img src={photoUrl} alt="Photo ID" className="rounded-lg" />
            )}

            {showUpload && (
              <>
                <button
                  type="button"
                  className="cursor-pointer border-0 focus:outline-none"
                  onClick={() => uploadRef.current?.click()}
                >
                  <div className="text-center">
                    <CameraOutlined className="mb-2 block text-3xl text-gray-6" />
                    <span className="text-lg text-gray-6">
                      {t('photo_upload_text')}
                    </span>
                  </div>
                </button>

                <input
                  type="file"
                  accept="image/jpeg,image/png"
                  className="hidden"
                  ref={uploadRef}
                  onChange={onChange}
                />
              </>
            )}
          </section>

          <Paragraph type="note">
            <i>{t('identity_verification_sub_body')}</i>
          </Paragraph>
        </IntakesLayoutContent>

        <IntakesLayoutFooter>
          {canGoBack && <CircleBack onClick={handleBack} />}
          <CircleSubmit valid={canSubmit} loading={false} />
        </IntakesLayoutFooter>
      </IntakesLayout>
    </form>
  );
}
