import { client } from 'constants/apiV2Clients';
import { usePatient } from 'providers/OnboardedPatientProvider';
import {
  IntakesLayout,
  IntakesLayoutContent,
  IntakesLayoutFooter,
} from 'components/pageLayout/IntakesLayout';
import { useTranslation } from 'react-i18next';
import { Heading, IntakeKicker } from 'components';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CircleSubmit, SearchInput } from 'components/forms';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useIntakeStore } from 'features/pg-Intakes/stores';
import { AnalyticsScreenName } from 'helpers/patientAnalytics';
import { BiologicalSexesForm } from './BiologicalSexesForm';

const schema = z.object({
  search: z.string().nonempty().trim(),
});
type Schema = z.infer<typeof schema>;

export function IntakesSearchPage() {
  const screenName: AnalyticsScreenName = 'IntakesSearchScreen';
  const { t } = useTranslation('frontitude');
  const navigate = useNavigate();
  const { accountId, patientAccount } = usePatient();
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const { control, handleSubmit, formState } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const [setQuery, setFormId, setFormName, setFirstQuestionId] = useIntakeStore(
    state => [
      state.setQuery,
      state.setFormId,
      state.setFormName,
      state.setFirstQuestionId,
    ]
  );
  const onlyValidForWeb = true;

  const isSaabEmpty = () => {
    return (
      patientAccount.sex_assigned_at_birth === null ||
      patientAccount.sex_assigned_at_birth === undefined ||
      patientAccount.sex_assigned_at_birth === 'unknown'
    );
  };

  const onSubmit = handleSubmit(async data => {
    // Create the new form and navigate to the intake filler-outer
    const response = await client.workflowsApi.workflowsAccountsFormsCreate(
      accountId,
      { form_workflow_id: data.search }
    );
    const { id, first_question_id, name } = response.data;
    setQuery(searchQuery ?? '');
    setFormId(id);
    setFormName(name);
    setFirstQuestionId(first_question_id);
    navigate(
      `/intakes/${id}?query=${searchQuery}&firstQuestionId=${first_question_id}&formName=${name}`
    );
  });

  if (isSaabEmpty()) {
    return <BiologicalSexesForm />;
  }
  return (
    <form data-testid="intakesSearchForm" onSubmit={onSubmit}>
      <IntakesLayout step="search" screenName={screenName}>
        <IntakesLayoutContent>
          <IntakeKicker>{t('preintake_header')}</IntakeKicker>

          <Heading>{t('main_content.help_prompt_subheader')}</Heading>

          <label htmlFor="search" className="mb-1 block text-charcoal-12">
            {t('form_group.medical_issue.label')}
          </label>
          <SearchInput
            dataTestId="searchInput"
            autoFocus
            control={control}
            name="search"
            onSearch={async value => {
              const response =
                await client.workflowsApi.workflowsAccountsFormsSearchList(
                  accountId,
                  value,
                  undefined,
                  onlyValidForWeb
                );

              setSearchQuery(value);

              const options = response.data
                .filter(item => item.form_workflow_id !== null)
                .map(item => ({
                  label: item.name,
                  value: item.form_workflow_id,
                }));

              return options;
            }}
          />
        </IntakesLayoutContent>
        <IntakesLayoutFooter>
          <CircleSubmit valid={formState.isValid} loading={false} />
        </IntakesLayoutFooter>
      </IntakesLayout>
    </form>
  );
}
