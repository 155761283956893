import { Account } from 'models';
import { parse } from 'date-fns';
import { SexAssignedAtBirthEnum, StateEnum } from '@galileo/core-api-client';
import { isUnder18FromDOB } from 'helpers';
import {
  FlaskApiGender,
  GenderIdentity,
  SexAssignedAtBirth,
} from './constants';

export function parseGenderForFlaskApi(
  sex?: SexAssignedAtBirth,
  genderIdentity?: GenderIdentity,
  otherGenderIdentity?: string
): [FlaskApiGender, GenderIdentity, string?] {
  if (sex && !genderIdentity && !otherGenderIdentity) {
    return ['unknown', 'unknown', undefined];
  }
  if (sex && genderIdentity && !otherGenderIdentity) {
    return ['unknown', genderIdentity, undefined];
  }
  if (sex && genderIdentity && otherGenderIdentity) {
    return ['unknown', genderIdentity, otherGenderIdentity];
  }

  throw new Error('Invalid gender identity');
}

export type MembershipStatus =
  | 'ACTIVE'
  | 'NO_MEMBERSHIP'
  | 'NO_WEB_ACCESS'
  | 'NO_PEDIATRICS';

export function getMembershipStatus(account: Account): MembershipStatus {
  const canOpenCases = account.entitlements.includes('can_open_cases');
  const hasPeds = account.entitlements.includes('pediatrics');
  const canAccessWeb = account.entitlements.includes('can_access_web');
  const isUnder18 = isUnder18FromDOB(
    parse(account.date_of_birth, 'yyyy-MM-dd', new Date())
  );

  switch (true) {
    case isUnder18 && !hasPeds:
      return 'NO_PEDIATRICS';

    case !canOpenCases:
      return 'NO_MEMBERSHIP';

    case !canAccessWeb:
      return 'NO_WEB_ACCESS';

    default:
      return 'ACTIVE';
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getValues<T extends Record<string, any>>(obj: T) {
  return Object.values(obj) as [(typeof obj)[keyof T]];
}

export const STATES = getValues(StateEnum);

export type State = (typeof StateEnum)[keyof typeof StateEnum];

export const getStateFromString = (
  input: string | undefined
): State | undefined => {
  if (input === undefined) {
    return input;
  }
  if (STATES.includes(input)) {
    return input as State;
  }
  return undefined;
};

export function getCoreApiSexAssignedAtBirth(
  input: string | undefined
): SexAssignedAtBirthEnum | undefined {
  if (input === undefined) {
    return input;
  }
  if (
    Object.values(SexAssignedAtBirthEnum).includes(
      input.toUpperCase() as SexAssignedAtBirthEnum
    )
  ) {
    return input.toUpperCase() as SexAssignedAtBirthEnum;
  }
  return undefined;
}
