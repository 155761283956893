import { differenceInYears } from 'date-fns';

/**
 * Determines if a patient is under 18 by taking a string
 * date of birth (formatted by FTUX) and subtracting it
 * from the current timestamp
 *
 * @param dateOfBirth
 * @returns boolean
 */
export const isUnder18 = (dateOfBirth: string): boolean => {
  if (validateDateOfBirth(dateOfBirth)) {
    const parsedDate = new Date(dateOfBirth);
    return isUnder18FromDOB(parsedDate);
  }
  return false;
};

/**
 * Determines if a patient is under 18 by taking a Date object
 * date of birth and subtracting it from the current timestamp
 *
 * @param dateOfBirth
 * @returns boolean
 */
export const isUnder18FromDOB = (dateOfBirth: Date): boolean => {
  const ageInYears = differenceInYears(Date.now(), dateOfBirth);
  return ageInYears < 18;
};

export const validateDateOfBirth = (date: string) => {
  // Validate date of birth is in the format MM/DD/YYYY or MM-DD-YYYY
  // eslint-disable-next-line no-useless-escape
  const dateOfBirthRegex = /^\d{1,2}[-\/.\ ]\d{1,2}[-\/.\ ]\d{4}$/;
  if (!dateOfBirthRegex.test(date)) {
    return false;
  }

  const parsedDate = new Date(date);
  if (Number.isNaN(parsedDate.getTime())) {
    return false;
  }
  const minDate = new Date('1900-01-01');
  const maxDate = new Date();
  if (parsedDate <= minDate || parsedDate >= maxDate) {
    return false;
  }
  return true;
};
