/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */

import { Question, QuestionResponseRequest } from '@galileo/core-api-client';
import { Heading, IntakeKicker, SubHeading } from 'components';
import { CircleBack, CircleSubmit } from 'components/forms';
import {
  IntakesLayout,
  IntakesLayoutContent,
  IntakesLayoutFooter,
} from 'components/pageLayout/IntakesLayout';
import { ChangeEventHandler, FormEvent, useRef, useState } from 'react';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { client } from 'constants/apiV2Clients';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { CameraOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { AnalyticsScreenName } from 'helpers/patientAnalytics';
import { QuestionPageProps } from './types';
import { Attachment } from './Attachment';

function parseQuestionResponseIntoDefaultValues(question: Question) {
  const { question_response } = question;

  if (!question_response) return [];
  if (question_response.resourcetype !== 'AttachmentWorkflowStepExecution')
    return [];

  return question_response.attachments ?? [];
}

export function AttachmentQuestion(props: QuestionPageProps) {
  const screenName: AnalyticsScreenName = 'AttachmentQuestionScreen';
  const { t } = useTranslation('frontitude');
  const queryClient = useQueryClient();
  const uploadRef = useRef<HTMLInputElement>(null);
  const { accountId } = usePatient();
  const { question, onSubmit, isSubmitting, onBack } = props;

  // Holds attachment IDs. Used by useQueries to trigger queries to get actual
  // attachment image data.
  const [attachmentIds, setAttachmentIds] = useState<string[]>(
    parseQuestionResponseIntoDefaultValues(question)
  );

  // Queries to get the actual attachment image data. Ends up as an array of
  // attachment IDs and image URLs.
  const photos = useQueries({
    queries: attachmentIds.map(attachmentId => ({
      queryKey: ['attachment', attachmentId],
      queryFn: async () => {
        const response =
          await client.workflowsApi.workflowsAccountsWorkflowInformationAttachmentsRetrieve(
            accountId,
            attachmentId,
            question.form.id,
            {
              responseType: 'blob',
            }
          );
        const url = URL.createObjectURL(response.data);
        return { attachmentId, url };
      },
    })),
  });

  // Uploads a new image and clears the query cache to load the image
  const uploadMutation = useMutation({
    mutationFn: async (file: File) => {
      const url = URL.createObjectURL(file);
      const response =
        await client.workflowsApi.workflowsAccountsFormsQuestionsAttachmentsCreate(
          accountId,
          question.form.id,
          question.id,
          { file },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

      return { id: response.data.id, url };
    },
    onSuccess: data => {
      setAttachmentIds([...attachmentIds, data.id]);
      queryClient.invalidateQueries(['attachment', data.id]);
    },
  });

  // Removes the attachment from the list
  const deleteAttachment = (attachmentId: string) => {
    setAttachmentIds(attachmentIds.filter(id => id !== attachmentId));
  };

  // Handles the file input onChange to start the upload
  const handleUpload: ChangeEventHandler<HTMLInputElement> = e => {
    const file: File | undefined = e.target.files?.[0];
    if (!file) return;
    if (!file.type.includes('image')) return;
    uploadMutation.mutate(file);
  };

  // Packs the attachment IDs into a QuestionResponseRequest and submits it
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const response: QuestionResponseRequest = {
      attachments: attachmentIds,
    };
    onSubmit(response);
  };

  return (
    <form onSubmit={handleSubmit}>
      <IntakesLayout step="intake" screenName={screenName}>
        <IntakesLayoutContent>
          <IntakeKicker>{question.form.name}</IntakeKicker>
          <Heading>{question.question_body}</Heading>
          {question.question_sub_body && (
            <SubHeading>{question.question_sub_body}</SubHeading>
          )}

          <section className="grid grid-cols-1 gap-2 md:grid-cols-2">
            {photos.map((photo, index) => (
              <Attachment
                key={attachmentIds[index]}
                attachment={photo}
                onRemove={deleteAttachment}
              />
            ))}
          </section>

          {photos.length === 0 ? (
            <section className="relative grid aspect-photo max-h-[24rem] w-1/2 grid-cols-1 items-center justify-center rounded-lg border border-gray-2 bg-gray-1">
              <button
                type="button"
                className="cursor-pointer border-0 focus:outline-none"
                onClick={() => uploadRef.current?.click()}
              >
                <div className="text-center">
                  <CameraOutlined className="mb-2 block text-3xl text-gray-6" />
                  <span className="text-lg text-gray-6">
                    {t('photo_upload_text')}
                  </span>
                </div>
              </button>
            </section>
          ) : (
            <button
              type="button"
              className="mt-4 border-none p-0 outline-none"
              onClick={() => uploadRef.current?.click()}
            >
              {t('main_content.medical_issue.add_another_photo_button')}
            </button>
          )}

          <input
            type="file"
            accept="image/*"
            className="hidden"
            ref={uploadRef}
            onChange={handleUpload}
          />
        </IntakesLayoutContent>

        <IntakesLayoutFooter>
          <div>{onBack && <CircleBack onClick={onBack} />}</div>
          <CircleSubmit
            valid={question.is_required ? photos.length >= 1 : true}
            loading={isSubmitting}
          />
        </IntakesLayoutFooter>
      </IntakesLayout>
    </form>
  );
}
