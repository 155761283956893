import { cn } from 'lib/util';
import { Link } from 'react-router-dom';

type ButtonLinkProps = {
  children: React.ReactNode;
  href: string;
  type?: 'primary' | 'default' | 'ghost';
  dataTestId?: string;
  disabled?: boolean;
};

export function ButtonLink(props: ButtonLinkProps) {
  const { children, href, dataTestId } = props;
  const type = props.type ?? 'default';
  const disabled = props.disabled ?? false;

  return (
    <Link to={href}>
      <button
        type="button"
        className={cn(
          'h-16 rounded-full border border-space-10 px-7 py-2.5 text-lg hover:border-space-10',
          type === 'primary' && 'bg-space-10 text-white',
          type === 'ghost' && 'bg-transparent text-space-10'
        )}
        data-testid={dataTestId}
        disabled={disabled}
      >
        {children}
      </button>
    </Link>
  );
}
