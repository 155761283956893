import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Heading, IntakeKicker, Paragraph } from 'components';
import {
  CircleBack,
  CircleSubmit,
  GenericError,
  TextInput,
} from 'components/forms';
import { useAddressAutoComplete } from 'components/forms/AddressAutoComplete';
import { useMutation } from '@tanstack/react-query';
import { usePatient } from 'providers/OnboardedPatientProvider';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import {
  IntakesLayout,
  IntakesLayoutContent,
  IntakesLayoutFooter,
} from 'components/pageLayout/IntakesLayout.tsx';
import { STATES } from 'features/NewUserOnboarding/util.ts';
import { updatePatientProfileService } from 'features/NewUserOnboarding/services.ts';
import { useIntakeStore } from './stores.ts';

const schema = z.object({
  street: z
    .string()
    .trim()
    .min(1, { message: i18n.t('is required') }),
  city: z
    .string()
    .trim()
    .min(1, { message: i18n.t('is required') }),
  state: z.enum(STATES),
  zip: z
    .string()
    .trim()
    .regex(/^\d{5}$/)
    .length(5, { message: i18n.t('is required') }),
});
type Schema = z.infer<typeof schema>;

type AddressEntryProps = {
  onNext: () => void;
  onBack: () => void;
  canGoBack: boolean;
};

export function AddressEntryForm(props: AddressEntryProps) {
  const screenName = 'AddressScreen';
  const { onNext, onBack, canGoBack } = props;
  const { t } = useTranslation('frontitude');
  const { patientId, patientAccount } = usePatient();
  const [
    storedAddress,
    setAddress,
    formName,
    lastQuestionId,
    setFirstQuestionId,
  ] = useIntakeStore(state => [
    {
      street: state.street,
      city: state.city,
      state: state.state,
      zip: state.zip,
    },
    state.setAddress,
    state.formName,
    state.lastQuestionId,
    state.setFirstQuestionId,
  ]);

  const defaultValues = {
    street: patientAccount.address_street ?? storedAddress.street,
    city: patientAccount.address_city ?? storedAddress.city,
    state: (patientAccount.address_state ??
      storedAddress.state) as (typeof STATES)[number],
    zip: patientAccount.address_zip ?? storedAddress.zip,
  };
  const { control, handleSubmit, formState, setValue, setError } =
    useForm<Schema>({
      resolver: zodResolver(schema),
      defaultValues,
    });

  const inputRef = useAddressAutoComplete({
    isAddressAutoComplete: true,
    onPlaceSelected: place => {
      if (place.street) {
        setValue('street', place.street, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
      if (place.city) {
        setValue('city', place.city, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
      if (place.state) {
        setValue('state', place.state, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
      if (place.zip) {
        setValue('zip', place.zip, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    },
  });
  const handleBack = () => {
    setFirstQuestionId(lastQuestionId);
    onBack();
  };

  const query = useMutation({
    mutationFn: (data: Schema) => {
      setAddress(data);
      return updatePatientProfileService(patientId, {
        ...data,
      });
    },
    onSuccess: () => {
      onNext();
    },
    onError: () => {
      setError('root.serverError', {
        message: t('demographics.unable_to_update_information_error'),
      });
    },
  });

  return (
    <form id="addressForm" onSubmit={handleSubmit(data => query.mutate(data))}>
      <IntakesLayout step="questions" screenName={screenName}>
        <IntakesLayoutContent>
          <IntakeKicker>{formName}</IntakeKicker>
          <Heading dataTestId="addressHeader">{t('address_header')}</Heading>
          <Paragraph type="note">{t('address_body')}</Paragraph>

          <GenericError message={formState.errors.root?.serverError?.message} />

          <TextInput
            name="street"
            label={t('address_line_1_label')}
            control={control}
            inputRef={inputRef}
            placeholder=""
            required
          />

          <TextInput
            name="city"
            label={t('city_label')}
            control={control}
            required
          />

          <TextInput
            name="state"
            label={t('state_label')}
            control={control}
            maxLength={2}
            required
          />

          <TextInput
            name="zip"
            label={t('zip_code_label')}
            control={control}
            maxLength={5}
            required
          />
        </IntakesLayoutContent>

        <IntakesLayoutFooter>
          {canGoBack && <CircleBack onClick={handleBack} />}
          <CircleSubmit valid={formState.isValid} loading={query.isLoading} />
        </IntakesLayoutFooter>
      </IntakesLayout>
    </form>
  );
}
