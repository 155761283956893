import { useMutation } from '@tanstack/react-query';
import { CircleBack, CircleSubmit } from 'components/forms';
import {
  IntakesLayout,
  IntakesLayoutContent,
  IntakesLayoutFooter,
} from 'components/pageLayout/IntakesLayout';
import { client } from 'constants/apiV2Clients';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AnalyticsScreenName } from 'helpers/patientAnalytics';
import { useIntakeStore } from './stores';

type IntakesConfirmationPageProps = {
  onBack: () => void;
  canGoBack: boolean;
};

export function IntakesConfirmationPage(props: IntakesConfirmationPageProps) {
  const screenName: AnalyticsScreenName = 'IntakesConfirmationScreen';
  const { onBack, canGoBack } = props;
  const { accountId } = usePatient();
  const navigate = useNavigate();

  const { t } = useTranslation('frontitude');

  const [formId, searchQuery, lastQuestionId, formName, setFirstQuestionId] =
    useIntakeStore(state => [
      state.formId,
      state.query,
      state.lastQuestionId,
      state.formName,
      state.setFirstQuestionId,
    ]);

  const mutation = useMutation({
    mutationFn: async () => {
      return client.workflowsApi.workflowsAccountsFormsCompletePartialUpdate(
        accountId,
        formId
      );
    },
    onSuccess: response => {
      // If the form is complete, navigate to scheduling
      if (response.status === 200) {
        navigate({
          pathname: `/schedule`,
          search: `?formId=${formId}&query=${searchQuery}&formName=${formName}`,
        });
      }
    },
  });

  const handleBack = () => {
    setFirstQuestionId(lastQuestionId);
    onBack();
  };

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        mutation.mutate();
      }}
    >
      <IntakesLayout step="schedule" screenName={screenName}>
        <IntakesLayoutContent>
          <div
            data-testid="intakeConfirmationContent"
            className="flex h-full w-full flex-col items-center justify-center gap-2 text-center"
          >
            <div className="w-96 font-serif text-4xl font-light leading-10">
              {t('main_content.appointment.book_prompt_header')}
            </div>
            <div className="w-96 font-sans text-xl font-light">
              {t('intake_process.main_content.review_intake_responses_advice')}
            </div>
          </div>
        </IntakesLayoutContent>
        <IntakesLayoutFooter>
          <div>{canGoBack && <CircleBack onClick={handleBack} />}</div>
          <CircleSubmit valid loading={mutation.isLoading} />
        </IntakesLayoutFooter>
      </IntakesLayout>
    </form>
  );
}
