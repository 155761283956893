import { assertUnreachable } from 'lib/util';
import { RegistrationCompleteLockout } from 'features/pg-RegistrationCompleteLockout';
import { useCachedQueryParams } from 'hooks/useCachedQueryParams';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { syncPhoneNumberFromMfaService } from 'features/pg-homePage/services';
import { useEffect, useState } from 'react';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import {
  PatientAnalytics,
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { NameEntryForm } from './NameEntryForm';
import { useNewUserOnboardingStore } from './store';
import { PhoneNumberEntryForm } from './PhoneNumberEntryForm';
import { PhoneNumberConfirmationForm } from './PhoneNumberConfirmationForm';
import { InsuranceEntryForm } from './InsuranceEntryForm';
import { InsuranceConfirmation } from './InsuranceConfirmation';
import { EmployeeSponsorshipForm } from './EmployeeSponsorshipForm';
import { PediatricsIneligible } from './PediatricsIneligible';
import { GalileoEligible } from './GalileoEligible';
import { useOnboardingNavigation } from './useOnboardingNavigation';
import { updateRegistrationSourceService } from './services';
import { DateOfBirthAndAddressForm } from './DateOfBirthAndAddressForm';

const registrationSourceSchema = z.object({
  patientId: z.string(),
  registrationPageSource: z.string(),
});
type RegistrationSourceSchema = z.infer<typeof registrationSourceSchema>;

const syncPhoneNumberFromMfaSchema = z.object({
  patientId: z.string(),
});
type SyncPhoneNumberFromMfaSchema = z.infer<
  typeof syncPhoneNumberFromMfaSchema
>;

export function NewUserOnboarding() {
  const [
    checkedForInsuranceAndSponsorship,
    currentStep,
    phoneConfirmed,
    setHasInsurance,
    setIsSponsored,
    setCheckedForInsuranceAndSponsorship,
    setPhoneNumber,
    setPhoneConfirmed,
    setSyncPhoneNumberResult,
    syncPhoneNumberResult,
  ] = useNewUserOnboardingStore(state => [
    state.checkedForInsuranceAndSponsorship,
    state.currentStep,
    state.phoneConfirmed,
    state.setHasInsurance,
    state.setIsSponsored,
    state.setCheckedForInsuranceAndSponsorship,
    state.setPhoneNumber,
    state.setPhoneConfirmed,
    state.setSyncPhoneNumberResult,
    state.syncPhoneNumberResult,
  ]);
  const { accountId, patientId, patientAccount, refetchAccount } = usePatient();
  const [hasEmittedMixpanelEvent, setHasEmittedMixpanelEvent] = useState(false);

  useOnboardingNavigation();
  const registrationPageSource = useCachedQueryParams(['source']).source;

  const updateRegistrationPageSource = useMutation({
    mutationKey: ['updateRegistrationPageSource', patientId],
    mutationFn: (data: RegistrationSourceSchema) =>
      updateRegistrationSourceService(
        data.patientId,
        data.registrationPageSource
      ),
  });

  const syncPhoneNumber = useMutation({
    mutationKey: ['syncPhoneNumberFromMfa', patientId],
    mutationFn: (data: SyncPhoneNumberFromMfaSchema) =>
      syncPhoneNumberFromMfaService(data.patientId),
  });

  // Update the registration page source when the user lands on the page
  useEffect(() => {
    if (
      registrationPageSource &&
      registrationPageSource.length &&
      updateRegistrationPageSource.isIdle
    ) {
      updateRegistrationPageSource.mutateAsync({
        patientId,
        registrationPageSource,
      });
    }
  }, [patientId, registrationPageSource, updateRegistrationPageSource]);

  // Send event to Mixpanel
  useEffect(() => {
    if (!hasEmittedMixpanelEvent) {
      Mixpanel.identify(accountId);
      Mixpanel.track(MixpanelEvents.FTUX_STARTED_POST_AUTH0);
      const subflowName: AnalyticsScreenName = 'PostAuth0Flow';
      const subflowSourceFlow: AnalyticsSourceFlow = 'ftux';
      PatientAnalytics.identify(accountId);
      PatientAnalytics.track(subflowName, 'Started', {
        source_flow: subflowSourceFlow,
      });
      setHasEmittedMixpanelEvent(true);
    }
  }, [accountId, hasEmittedMixpanelEvent, setHasEmittedMixpanelEvent]);

  // Sync phone number from MFA
  useEffect(() => {
    if (!patientAccount || phoneConfirmed) return;

    const checkForConfirmedPhoneNumber = () => {
      // If the phone number is confirmed
      // Set the phone number in state and mark it as confirmed
      if (
        patientAccount?.mobile_phone_number &&
        patientAccount?.status_phone_number_verification === 1
      ) {
        setPhoneNumber({ phoneNumber: patientAccount.mobile_phone_number });
        setPhoneConfirmed();
      }
    };

    async function syncPhoneNumberFromMfa() {
      // If the phone number is not confirmed and we haven't attempted to sync it yet
      // Attempt to sync the phone number from MFA and set the result in state
      if (!syncPhoneNumberResult && syncPhoneNumber.isIdle) {
        try {
          const responseStatus = await syncPhoneNumber.mutateAsync({
            patientId,
          });
          const result = responseStatus === 200 ? 'SUCCESS' : 'FAILURE';
          setSyncPhoneNumberResult(result);
        } catch (error) {
          setSyncPhoneNumberResult('FAILURE');
        }
      }
    }

    const refetchAccountIfSynced = () => {
      // If the phone number was successfully synced from MFA and the phone number is not confirmed
      // Refetch the account to get the updated phone number
      if (syncPhoneNumberResult === 'SUCCESS') {
        refetchAccount();
      }
    };

    checkForConfirmedPhoneNumber();
    syncPhoneNumberFromMfa();
    refetchAccountIfSynced();
  }, [
    patientAccount,
    patientAccount.mobile_phone_number,
    patientAccount?.status_phone_number_verification,
    patientId,
    phoneConfirmed,
    refetchAccount,
    setPhoneConfirmed,
    setPhoneNumber,
    setSyncPhoneNumberResult,
    syncPhoneNumber,
    syncPhoneNumberResult,
  ]);

  // Check if the user has insurance or sponsorship
  useEffect(() => {
    // If the patient has insurance or sponsorship already those Onboarding steps can be skipped
    // Set the values in state so the Onboarding steps can be skipped later in the flow
    if (patientAccount && !checkedForInsuranceAndSponsorship) {
      setHasInsurance(patientAccount.insurance.length > 0);
      setIsSponsored(patientAccount.membership !== null);
      setCheckedForInsuranceAndSponsorship();
    }
  }, [
    checkedForInsuranceAndSponsorship,
    patientAccount,
    setCheckedForInsuranceAndSponsorship,
    setHasInsurance,
    setIsSponsored,
  ]);

  const screenSourceFlow: AnalyticsSourceFlow = 'ftux';

  switch (currentStep) {
    case 'NAME_ENTRY':
      return <NameEntryForm />;

    case 'DATE_OF_BIRTH_ADDRESS_ENTRY':
      return <DateOfBirthAndAddressForm />;

    case 'PHONE_NUMBER_ENTRY':
      return <PhoneNumberEntryForm />;

    case 'PHONE_NUMBER_CONFIRMATION':
      return <PhoneNumberConfirmationForm />;

    case 'EMPLOYEE_SPONSORSHIP':
      return <EmployeeSponsorshipForm />;

    case 'INSURANCE_ENTRY':
      return <InsuranceEntryForm />;

    case 'INSURANCE_CONFIRMATION':
      return <InsuranceConfirmation />;

    case 'PEDIATRICS_INELIGIBLE':
      return <PediatricsIneligible screenSourceFlow={screenSourceFlow} />;

    case 'GALILEO_ELIGIBLE':
      return <GalileoEligible />;

    case 'SPONSORSHIP_REQUIRED':
      return (
        <RegistrationCompleteLockout screenSourceFlow={screenSourceFlow} />
      );

    case 'WEB_ACCESS_REQUIRED':
      return (
        <RegistrationCompleteLockout screenSourceFlow={screenSourceFlow} />
      );

    default:
      assertUnreachable(currentStep);
  }
}
