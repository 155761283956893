import {
  Button,
  CircleSubmit,
  GenericError,
  TextInput,
} from 'components/forms';
import {
  Heading,
  Paragraph,
  PageLayout,
  PageLayoutContent,
  PageLayoutFooter,
  FullPageLoading,
} from 'components';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { Account } from 'models';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import {
  PatientAnalytics,
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import { useCachedQueryParams } from 'hooks/useCachedQueryParams';
import { useNewUserOnboardingStore } from './store';
import { confirmEmployeeSponsorshipService } from './services';
import { getMembershipStatus } from './util';
import { setNextStepFor, setSkipStepFor } from './stepFlows/stepFlows';

const schema = z.object({
  accessCode: z.string(),
});

type Schema = z.infer<typeof schema>;

export function EmployeeSponsorshipForm() {
  const screenName: AnalyticsScreenName = 'EmployeeSponsorshipScreen';
  const screenSourceFlow: AnalyticsSourceFlow = 'ftux';
  const { t } = useTranslation('frontitude');
  const { accountId } = usePatient();
  const { control, formState, handleSubmit, setError } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      accessCode: undefined,
    },
  });

  const [dateOfBirth, setEmployeeSponsorship] = useNewUserOnboardingStore(
    state => [state.dateOfBirth, state.setEmployeeSponsorship]
  );

  if (!accountId) throw new Error('accountID is required');
  if (!dateOfBirth) throw new Error('dateOfBirth is required');

  const mutation = useMutation({
    mutationFn: (data: Schema) => {
      return confirmEmployeeSponsorshipService({
        accountId,
        accessCode: data.accessCode,
      });
    },
    onSuccess: (data: Account) => {
      Mixpanel.track(MixpanelEvents.FTUX_SUBMITTED_EMPLOYEE_SPONSORSHIP_SCREEN);
      PatientAnalytics.track(screenName, 'Submitted', {
        source_flow: screenSourceFlow,
      });
      setNextStepFor('EMPLOYEE_SPONSORSHIP', {
        membershipStatus: getMembershipStatus(data),
      });
    },
    onError: () => {
      PatientAnalytics.track(screenName, 'Errored', {
        source_flow: screenSourceFlow,
      });
      setError('root.serverError', {
        message: t('employer.code_not_recognized_error'),
      });
    },
  });

  const accessCodeValues = useCachedQueryParams([
    'accessCode',
    'accesscode',
    'access_code',
  ]);
  const accessCode =
    accessCodeValues.accessCode ||
    accessCodeValues.accesscode ||
    accessCodeValues.access_code;
  useEffect(() => {
    if (mutation.isIdle && accessCode) {
      mutation.mutate({ accessCode });
    }
  }, [mutation, accessCode]);

  const onSubmit = handleSubmit(data => {
    setEmployeeSponsorship({ employeeSponsorshipAccessCode: data.accessCode });
    return mutation.mutate(data);
  });

  const onSkip = () => {
    Mixpanel.track(MixpanelEvents.FTUX_SKIPPED_EMPLOYEE_SPONSORSHIP_SCREEN);
    PatientAnalytics.track(screenName, 'Skipped', {
      source_flow: screenSourceFlow,
    });
    setSkipStepFor('EMPLOYEE_SPONSORSHIP');
  };

  if (mutation.isLoading) {
    return <FullPageLoading />;
  }
  return (
    <form onSubmit={onSubmit}>
      <PageLayout
        progress={6 / 7}
        screenName={screenName}
        screenSourceFlow={screenSourceFlow}
      >
        <PageLayoutContent>
          <Heading dataTestId="accessCodeHeader">
            {t('access_code_header')}
          </Heading>
          <Paragraph type="note">{t('access_code_body')}</Paragraph>

          <GenericError message={formState.errors.root?.serverError?.message} />
          <TextInput
            control={control}
            name="accessCode"
            label={t('access_code_label')}
          />

          <Paragraph>
            {t('access_code_need_help')} <br />
            <a href="mailto:support@galileohealth.com">
              {t('access_code_contact_us')}
            </a>
          </Paragraph>
        </PageLayoutContent>
        <PageLayoutFooter>
          <div className="w-42 grid grid-cols-[min-content_min-content] gap-4">
            <Button type="ghost" onClick={onSkip}>
              {t('skip_label')}
            </Button>
            <CircleSubmit
              valid={formState.isValid}
              loading={mutation.isLoading}
            />
          </div>
        </PageLayoutFooter>
      </PageLayout>
    </form>
  );
}
