import { FC, useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { SubHeading } from 'components';

import { usePatient } from 'providers/OnboardedPatientProvider';
import { useNavigate } from 'react-router-dom';
import { HealthWebCaseAndCareplan } from '@galileo/core-api-client';
import {
  findAddressLatLong,
  isGoogleMapsMapsLibraryLoaded,
} from 'helpers/googleMaps';
import { useTranslation } from 'react-i18next';
import { HomePageLayout, HomePageLayoutContent } from '../../components';
import { Appointment } from '../../models';
import { Cases } from './Cases';

import { Appointments } from './Appointments';
import { DownloadAppBanner } from './DownloadAppBanner';
import { useHomePageStore } from './store';

type TimeBasedGreetingProps = {
  name: string;
  appointment: Appointment | undefined;
};

const TimeBasedGreeting = (props: TimeBasedGreetingProps) => {
  const { t } = useTranslation('frontitude');
  const { name, appointment } = props;

  const date = new Date();
  const hours = date.getHours();

  let cN = '';
  if (appointment) {
    cN = '';
  } else cN = 'text-start text-3xl text-charcoal-12';

  if (hours < 12) {
    return (
      <SubHeading className={cN}>
        {t('home_header_morning', { first_name: name })}
      </SubHeading>
    );
  }
  if (hours < 18) {
    return (
      <SubHeading className={cN}>
        {t('homescreen.greeting.personalized_afternoon', { first_name: name })}
      </SubHeading>
    );
  }
  return (
    <SubHeading className={cN}>
      {t('homescreen.greeting.evening', { first_name: name })}
    </SubHeading>
  );
};

type HomePageDefaultProps = {
  appointment: Appointment | undefined;
  cases: HealthWebCaseAndCareplan[];
  isLoading: boolean;
};

const HomePageDefault: FC<HomePageDefaultProps> = (
  props: HomePageDefaultProps
) => {
  const { appointment, cases, isLoading } = props;
  const { patientAccount } = usePatient();
  const navigate = useNavigate();
  const [isGoogleMapsLoading, setIsGoogleMapsLoading] = useState(true);
  const [patientLatLong, setPatientLatLong] = useHomePageStore(state => [
    state.patientLatLong,
    state.setPatientLatLong,
  ]);

  useEffect(() => {
    if (isGoogleMapsMapsLibraryLoaded() && patientLatLong !== null) {
      setIsGoogleMapsLoading(false);
      return;
    }
    findAddressLatLong(
      patientAccount.address_street ?? '',
      patientAccount.address_city ?? '',
      patientAccount.address_state,
      patientAccount.address_zip ?? ''
    ).then(res => {
      setPatientLatLong(res);
    });
    setIsGoogleMapsLoading(false);
  }, [patientAccount, patientLatLong, setPatientLatLong]);

  return (
    <HomePageLayout>
      <HomePageLayoutContent className="mx-auto max-w-2xl">
        <Skeleton data-testid="skeleton" loading={isLoading} />
        {!isLoading && !isGoogleMapsLoading && (
          <>
            <TimeBasedGreeting
              name={patientAccount.preferred_name || patientAccount.first_name}
              appointment={appointment}
            />
            <Appointments appointment={appointment} navigate={navigate} />
            <DownloadAppBanner />
            <Cases cases={cases} />
          </>
        )}
      </HomePageLayoutContent>
    </HomePageLayout>
  );
};

export default HomePageDefault;
