import { FC, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { SuspensionViewType } from '@zoom/meetingsdk/embedded';
import { log } from 'lib/log';
import { zoomRole } from 'constants/zoom';
import { useTranslation } from 'react-i18next';
import { zoomClient, ZoomConfig } from '../../helpers';
import { env } from '../../lib';
import { createZoomJWTSignatureService } from './services';
import './ZoomComponent.css';

function detectMob() {
  return window.innerWidth <= 800 || window.innerHeight <= 600;
}
export interface ZoomComponentProps {
  zoomConfig: ZoomConfig;
}

export const ZoomComponent: FC<ZoomComponentProps> = ({
  zoomConfig,
}: ZoomComponentProps) => {
  const { t } = useTranslation('frontitude');
  const zoomAppRootRef = useRef<HTMLDivElement>();
  const navigate = useNavigate();

  useEffect(() => {
    const getDefaults = () => {
      let viewSizes;
      if (detectMob()) {
        viewSizes = {
          default: {
            width: window.innerWidth,
            height: window.innerHeight * 0.9,
          },
        };
      } else {
        viewSizes = {
          default: {
            width: window.innerWidth * 0.7,
            height: window.innerHeight * 0.7,
          },
        };
      }

      return viewSizes;
    };

    const startMeeting = async () => {
      try {
        log.info('Zoom startMeeting initiated', {
          accountId: zoomConfig.accountId,
        });
        const response = await createZoomJWTSignatureService(
          zoomConfig.accountId,
          zoomConfig.meetingNumber,
          zoomRole
        );
        log.info('Zoom JWT created', {
          accountId: zoomConfig.accountId,
        });
        await zoomClient.init({
          zoomAppRoot: zoomAppRootRef.current,
          language: 'en-US', // TODO Figure out if we can pull this from the i18n language detection
          patchJsMedia: true,
          customize: {
            video: {
              defaultViewType: 'gallery' as SuspensionViewType,
              isResizable: true,
              viewSizes: getDefaults(),
            },
            toolbar: {
              buttons: [
                {
                  text: t('join_video_visit.cta.leave_meeting_button'),
                  className: 'leave-meeting',
                  onClick: () => {
                    zoomClient.leaveMeeting();
                    navigate('/thank-you');
                  },
                },
              ],
            },
          },
        });
        log.info('Zoom client initialized', {
          accountId: zoomConfig.accountId,
        });
        zoomClient
          .join({
            sdkKey: env.VITE_ZOOM_MEETING_CLIENT_ID,
            signature: response.signature,
            meetingNumber: zoomConfig.meetingNumber,
            password: zoomConfig.password,
            userName: zoomConfig.userName,
          })
          .then(() =>
            log.info('Joined Zoom meeting', { accountId: zoomConfig.accountId })
          )
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((e: any) => {
            console.error('Error joining Zoom meeting', e);
          });
      } catch (e) {
        console.error('Error starting Zoom meeting', e);
      }
    };

    startMeeting();
  });

  return (
    <div className="zoom-container flex h-screen w-screen flex-row items-center justify-center bg-space-10">
      {/* @ts-expect-error - this is an issue with the Zoom SDK */}
      <div ref={zoomAppRootRef} id="meetingSDKElement" />
    </div>
  );
};
