import {
  ButtonLink,
  Heading,
  PageLayout,
  PageLayoutContent,
  Paragraph,
} from 'components';
import { Button } from 'components/forms';
import Lottie from 'lottie-react';
import successAnimation from 'assets/success-animation.json';
import { useTranslation } from 'react-i18next';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { sendDownloadLinkService } from 'features/NewUserOnboarding/services';
import userAgent from 'helpers/userAgent';
import { useState } from 'react';
import {
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';

type LockoutProps = {
  screenSourceFlow: AnalyticsSourceFlow;
};

export const RegistrationCompleteLockout = (props: LockoutProps) => {
  const { screenSourceFlow } = props;
  const screenName: AnalyticsScreenName = 'RegistrationCompleteLockoutScreen';
  const { t } = useTranslation('frontitude');
  const {
    patientAccount: {
      mobile_country_code: countryCode,
      mobile_phone_number: phoneNumber,
      language_id: language,
    },
  } = usePatient();

  const [submitBtnMessage, setSubmitBtnMessage] = useState(
    t('text_link_button')
  );

  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async () => {
    try {
      await sendDownloadLinkService(countryCode, phoneNumber, language);
      setSubmitBtnMessage(t('download_app_banner.message_sent_confirmation'));
      setDisabled(true);
    } catch (error) {
      setSubmitBtnMessage(t('download_app_banner.try_again_button'));
    }
  };

  return (
    <PageLayout
      whiteBackground
      progress={9 / 9}
      screenName={screenName}
      screenSourceFlow={screenSourceFlow}
    >
      <PageLayoutContent centered>
        <div className="grid place-items-center pb-8">
          <Lottie
            className="w-32"
            animationData={successAnimation}
            loop={false}
          />
        </div>

        <Heading dataTestId="successHeader">
          {t('claimed_sponsorship_success_header')}
        </Heading>
        <Paragraph className="mb-8">{t('claimed_sponsorship_body')}</Paragraph>
        {userAgent.isMobile() ? (
          <ButtonLink
            href="https://app.adjust.com/dr4swbk?source=healthweb"
            dataTestId="downloadGalileoAppButton"
          >
            {t('text_link_button')}
          </ButtonLink>
        ) : (
          <Button
            type={disabled ? 'ghost' : 'primary'}
            onClick={handleSubmit}
            dataTestId="textDownloadLinkButton"
            disabled={disabled}
          >
            {submitBtnMessage}
          </Button>
        )}
        <Paragraph className="mt-8">
          {t('claimed_sponsorship_footer', {
            support_email: '',
          })}
          <a href="mailto:support@galileo.io" className="text-black">
            support@galileo.io
          </a>
        </Paragraph>
      </PageLayoutContent>
    </PageLayout>
  );
};
