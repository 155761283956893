import {
  Heading,
  SubHeading,
  PostVideoVisitLayout,
  PostVideoVisitLayoutContent,
} from 'components';
import { ReactComponent as YourGalileoTeam } from 'assets/your-galileo-team.svg';
import { FC } from 'react';
import { Button } from 'antd';
import { useCookies } from 'react-cookie';
import { screenTypes } from 'constants/screenTypes';
import { useTranslation } from 'react-i18next';

type NoShowPageProps = {
  setScreen: (screen: string) => void;
  expiration: Date;
};

const NoShowPage: FC<NoShowPageProps> = ({ setScreen, expiration }) => {
  const { t } = useTranslation('frontitude');
  const [_cookies, setCookie] = useCookies(['noShowStatus']);

  const onClose = () => {
    setCookie('noShowStatus', 'dismissed', {
      path: '/',
      expires: expiration,
    });
    setScreen(screenTypes.HOME_DEFAULT);
  };

  return (
    <PostVideoVisitLayout onClose={onClose}>
      <PostVideoVisitLayoutContent>
        <div
          data-testid="noShow"
          className="flex flex-col items-center text-center"
        >
          <Heading>
            {t('appointment_no_show.header.missed_appointment_title')}
          </Heading>

          <div className="my-5 max-w-md items-center text-center">
            <p>{t('appointment_complete.notification.time_passed')}</p>
          </div>

          <SubHeading>
            {t('appointment_complete.message.formal_closing_text')}
          </SubHeading>
          <div className="mx-auto w-52 max-w-md sm:flex sm:flex-col sm:items-center sm:justify-center">
            <YourGalileoTeam className="mx-auto block h-20 rounded-full sm:mx-0 sm:shrink-0" />
          </div>
          <div className="mx-auto w-52 max-w-md sm:flex sm:flex-col sm:items-center sm:justify-center sm:space-y-0">
            <Button
              data-testid="noShowDoneButton"
              className="rounded-full bg-charcoal-2"
              onClick={onClose}
            >
              {t('appointment_complete.cta.done_button')}
            </Button>
          </div>
        </div>
      </PostVideoVisitLayoutContent>
    </PostVideoVisitLayout>
  );
};

export default NoShowPage;
