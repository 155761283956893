import { CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Medical } from 'assets/medical.svg';
import { ReactComponent as Profile } from 'assets/profile.svg';

import { cn } from 'lib/util';

type ProgressStep = 'search' | 'intake' | 'questions' | 'schedule';
type ProgressState = 'complete' | 'active' | 'incomplete';

// States for the other progress bits based on the overall current progress state
const PROGRESS_STATES: {
  [key in ProgressStep]: { [subkey in ProgressStep]: ProgressState };
} = {
  search: {
    search: 'active',
    intake: 'incomplete',
    questions: 'incomplete',
    schedule: 'incomplete',
  },
  intake: {
    search: 'complete',
    intake: 'active',
    questions: 'incomplete',
    schedule: 'incomplete',
  },
  questions: {
    search: 'complete',
    intake: 'complete',
    questions: 'active',
    schedule: 'incomplete',
  },
  schedule: {
    search: 'complete',
    intake: 'complete',
    questions: 'complete',
    schedule: 'active',
  },
};

type ProgressSegmentProps = {
  icon: React.ReactNode;
  label: string;
  state: ProgressState;
};

function ProgressSegment(props: ProgressSegmentProps) {
  const { icon, label, state } = props;
  return (
    <li
      className={cn(
        'grid grid-cols-1 place-items-center p-1 first:rounded-l-full last:rounded-r-full',
        (state === 'active' || state === 'complete') &&
          'bg-earth-600 text-white'
      )}
    >
      <div
        className={cn(
          // If active, we always have the full 2 columsn inner grid. If not,
          // then we need to rely on responsive CSS to determin how many
          // columns we have (e.g. default to 1, show 2 on desktop)
          'grid items-center gap-2',
          state === 'active'
            ? 'grid-cols-[min-content_1fr]'
            : 'grid-cols-1 md:grid-cols-[min-content_1fr]'
        )}
      >
        <div>{icon}</div>
        <div
          className={cn(
            // Hide label by default on mobile, but show it if active
            'hidden whitespace-nowrap md:inline-block',
            state === 'active' && 'inline-block'
          )}
        >
          {label}
        </div>
      </div>
    </li>
  );
}

type ProgressDividerProps = {
  state: ProgressState;
};

export function ProgressDivider(props: ProgressDividerProps) {
  const { state } = props;

  // Progress divider is two overlaid triangles with a 1px offset. We change
  // the background color and border colors of the triangles to change from an
  // outline chevron to a solid chevron based on state.
  return (
    <li className={cn('relative w-2', state === 'complete' && 'bg-earth-600')}>
      <div
        className={cn(
          'absolute left-[-1px] z-20 h-0 w-0 border-b-[1rem] border-l-[.5rem] border-t-[1rem] border-b-transparent border-t-transparent',
          state === 'incomplete' ? 'border-l-ivory-6' : 'border-l-earth-600'
        )}
      />
      <div
        className={cn(
          'absolute z-10 h-0 w-0 border-b-[1rem] border-l-[.5rem] border-t-[1rem] border-b-transparent border-t-transparent',
          state === 'complete' ? 'border-l-ivory-6' : 'border-l-earth-600'
        )}
      />
    </li>
  );
}

// Alternate grid columns layout for compressed mobile view
// ┌─────────────┬───┬─────────────┬───┬─────────────┬───┬─────────────┐
// │ State Label │ > │ State Label │ > │ State Label │ > │ State Label │
// └─────────────┴───┴─────────────┴───┴─────────────┴───┴─────────────┘
const MOBILE_STEP_GRID = {
  search:
    'grid-cols-[1fr_min-content_2.5rem_min-content_2.5rem_min-content_2.5rem]',
  intake:
    'grid-cols-[2.5rem_min-content_1fr_min-content_2.5rem_min-content_2.5rem]',
  questions:
    'grid-cols-[2.5rem_min-content_2.5rem_min-content_1fr_min-content_2.5rem]',
  schedule:
    'grid-cols-[2.5rem_min-content_2.5rem__min-content_2.5rem_min-content_1fr]',
};

export type IntakesProgressProps = {
  step: ProgressStep;
};

export function IntakesProgress(props: IntakesProgressProps) {
  const { t } = useTranslation('frontitude');
  const { step } = props;

  return (
    <ul
      className={cn(
        `grid h-8 rounded-full bg-ivory-6 text-sm text-earth-600 md:grid-cols-4`,
        `${MOBILE_STEP_GRID[step]} md:grid-cols-[1fr_min-content_1fr_min-content_1fr_min-content_1fr]`
      )}
    >
      <ProgressSegment
        icon={<SearchOutlined />}
        label={t('intake_progress_search')}
        state={PROGRESS_STATES[step].search}
      />
      <ProgressDivider state={PROGRESS_STATES[step].search} />
      <ProgressSegment
        icon={<Medical className="mb-1 inline-block h-[15px] fill-current" />}
        label={t('intake_progress_questions')}
        state={PROGRESS_STATES[step].intake}
      />
      <ProgressDivider state={PROGRESS_STATES[step].intake} />
      <ProgressSegment
        icon={<Profile className="mb-1 inline-block h-[15px] fill-current" />}
        label={t('intake_progress_add_info')}
        state={PROGRESS_STATES[step].questions}
      />
      <ProgressDivider state={PROGRESS_STATES[step].questions} />
      <ProgressSegment
        icon={<CalendarOutlined />}
        label={t('intake_progress_schedule')}
        state={PROGRESS_STATES[step].schedule}
      />
    </ul>
  );
}
