import { useQuery } from '@tanstack/react-query';
import { FullPageLoading, HomePageLayout } from 'components';
import { IntakesProgress } from 'components/pageLayout/IntakesProgress';
import { getAppointmentsDetailsService } from 'features/pg-homePage/services';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

const IS_HEALTH_WEB_CASE_FIELD = 'field:14043187';
const FORM_ID_FIELD = 'field:14691996';
const FORM_NAME_FIELD = 'field:14763061';
const SEARCH_QUERY_FIELD = 'field:14762418';

const SchedulingPage: FC = () => {
  const { t } = useTranslation('frontitude');
  const { patientAccount } = usePatient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const formId = searchParams.get('formId');
  const searchQuery = searchParams.get('query');
  const formName = searchParams.get('formName');
  const healthWebAcuitySchedulingLink = `${patientAccount?.acuity_scheduling_link_web}&${IS_HEALTH_WEB_CASE_FIELD}=True&${FORM_ID_FIELD}=${formId}&${SEARCH_QUERY_FIELD}=${searchQuery}&${FORM_NAME_FIELD}=${formName}`;

  const query = useQuery({
    queryKey: ['patientAppointments', patientAccount.account_id],
    queryFn: async () => {
      const response = await getAppointmentsDetailsService(
        patientAccount.account_id
      );
      if (response?.data?.data?.length > 0) {
        navigate('/');
      }
      return response;
    },
    onError: error => {
      console.log('Unable to get appointment details', error);
    },
  });

  if (query.isLoading) return <FullPageLoading />;

  return (
    <div>
      <HomePageLayout>
        <div
          className="flex flex-col items-center"
          data-testid="schedulingPage"
        >
          <div className="orphan-progress-layout-mobile md:orphan-progress-layout-desktop">
            <div>{/* SPACER */}</div>
            <div className="py-3">
              <IntakesProgress step="schedule" />
            </div>
            <div>{/* SPACER */}</div>
          </div>
          <div className="h-full">
            <iframe
              src={healthWebAcuitySchedulingLink}
              title={t('book_visit_banner_button')}
              style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}
            />
            <script
              src="https://embed.acuityscheduling.com/js/embed.js"
              type="text/javascript"
            />
          </div>
          <div className="my-10 w-full rounded-md bg-slate-10 p-4 text-center text-white md:w-[900px]">
            {t('join_video_visit.confirmation.instruction')}
          </div>
        </div>
      </HomePageLayout>
    </div>
  );
};

export default SchedulingPage;
