import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutFooter,
  Heading,
  Paragraph,
} from 'components';
import {
  CircleBack,
  CircleSubmit,
  GenericError,
  TextInput,
} from 'components/forms';
import { useMutation } from '@tanstack/react-query';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import {
  PatientAnalytics,
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import i18n from 'i18n';
import { useNewUserOnboardingStore } from './store';
import { requestPhoneConfirmationService } from './services';
import { setNextStepFor, setPreviousStepFor } from './stepFlows/stepFlows';

const schema = z.object({
  phoneNumber: z
    .string()
    .trim()
    .length(10, { message: i18n.t('is required') }),
});
type Schema = z.infer<typeof schema>;

export function PhoneNumberEntryForm() {
  const screenName: AnalyticsScreenName = 'PhoneNumberScreen';
  const screenSourceFlow: AnalyticsSourceFlow = 'ftux';
  const { t } = useTranslation('frontitude');
  const { accountId } = usePatient();
  const [setPhoneNumber, storedPhoneNumber] = useNewUserOnboardingStore(
    state => [state.setPhoneNumber, state.phoneNumber]
  );
  if (!accountId) throw new Error('accountID is required');

  const { control, handleSubmit, setError, formState, watch, setValue } =
    useForm<Schema>({
      resolver: zodResolver(schema),
      defaultValues: {
        phoneNumber: storedPhoneNumber,
      },
    });

  const query = useMutation({
    mutationFn: (data: Schema) => {
      return requestPhoneConfirmationService({
        accountId,
        countryCode: '1',
        ...data,
      });
    },
    onSuccess: (_, inputs) => {
      Mixpanel.track(MixpanelEvents.FTUX_SUBMITTED_PHONE_NUMBER_SCREEN);
      PatientAnalytics.track(screenName, 'Submitted', {
        source_flow: screenSourceFlow,
      });
      setPhoneNumber(inputs);
      setNextStepFor('PHONE_NUMBER_ENTRY');
    },
    onError: () => {
      PatientAnalytics.track(screenName, 'Errored', {
        source_flow: screenSourceFlow,
      });
      setError('root.serverError', {
        message: t('2fa.something_wrong_error'),
      });
    },
  });

  const onBack = () => {
    setPreviousStepFor('PHONE_NUMBER_ENTRY');
  };

  const cleanPhoneNumber = (phoneNumber: string) => {
    const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');
    return formattedPhoneNumber;
  };

  const enteredPhoneNumber = watch('phoneNumber');
  useEffect(() => {
    if (!watch('phoneNumber')) return;
    const number = cleanPhoneNumber(enteredPhoneNumber);
    setValue('phoneNumber', number);
  }, [enteredPhoneNumber, setValue, watch]);

  return (
    <form onSubmit={handleSubmit(data => query.mutate(data))}>
      <PageLayout
        progress={3 / 7}
        screenName={screenName}
        screenSourceFlow={screenSourceFlow}
      >
        <PageLayoutContent>
          <Heading dataTestId="phoneNumberHeader">
            {t('phone_number_header')}
          </Heading>
          <Paragraph dataTestId="phoneNumberBody" type="note">
            {t('phone_number_body')}
          </Paragraph>

          <GenericError message={formState.errors.root?.serverError?.message} />

          <TextInput
            type="tel"
            name="phoneNumber"
            label={t('phone_number_label')}
            control={control}
            maxLength={10}
            required
          />
        </PageLayoutContent>

        <PageLayoutFooter>
          <CircleBack onClick={onBack} />
          <CircleSubmit valid={formState.isValid} loading={query.isLoading} />
        </PageLayoutFooter>
      </PageLayout>
    </form>
  );
}
