import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Space } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useForm } from 'react-hook-form';
import {
  FullPageLoading,
  PageLayout,
  PageLayoutContent,
  PageLayoutFooter,
} from 'components';
import {
  CircleSubmit,
  TextInput,
  Checkbox,
  GenericError,
  InsuranceAutoComplete,
  CircleBack,
} from 'components/forms';
import { useTranslation } from 'react-i18next';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import {
  PatientAnalytics,
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usePatient } from 'providers/OnboardedPatientProvider';
import Lottie from 'lottie-react';
import loadingAnimation from 'assets/loading-animation.json';
import { useState } from 'react';
import { InsuranceCarrier } from '@galileo/core-api-client';
import { Heading, Paragraph } from '../../components/typography/Typography';
import { useNewUserOnboardingStore } from './store';
import {
  checkPatientCoverage,
  getPayorsCarriersList,
  toggleMedicareMedicaidStatus,
} from './services';
import { setNextStepFor, setPreviousStepFor } from './stepFlows/stepFlows';

const schema = z
  .object({
    insuranceProvider: z.string().default(''),
    insuranceMemberID: z.string().default(''),
    isNotInsured: z.boolean(),
    medicareEnabled: z.boolean(),
    medicaidEnabled: z.boolean(),
  })
  .refine(data => {
    if (
      data.isNotInsured === true &&
      !data.insuranceProvider &&
      !data.medicaidEnabled &&
      !data.medicareEnabled &&
      !data.insuranceMemberID
    ) {
      return true;
    }
    if (
      (data.isNotInsured === false || data.isNotInsured === undefined) &&
      data.insuranceProvider &&
      data.insuranceMemberID
    ) {
      return true;
    }
    return false;
  });

type Schema = z.infer<typeof schema>;

export function InsuranceEntryForm() {
  const screenName: AnalyticsScreenName = 'InsuranceInfoScreen';
  const screenSourceFlow: AnalyticsSourceFlow = 'ftux';
  const { t } = useTranslation('frontitude');
  const { patientId, accountId } = usePatient();
  const {
    control,
    formState,
    handleSubmit,
    trigger,
    watch,
    setValue,
    resetField,
    setError,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      insuranceProvider: '',
      insuranceMemberID: '',
      isNotInsured: false,
      medicareEnabled: false,
      medicaidEnabled: false,
    },
  });

  const [isSponsored, setIsInsuranceEligible] = useNewUserOnboardingStore(
    state => [state.isSponsored, state.setIsInsuranceEligible]
  );

  const [insuranceCarriers, setInsuranceCarriers] = useState<
    InsuranceCarrier[]
  >([]);

  const checkNoInsuranceBoxChangeHandler = (e: CheckboxChangeEvent) => {
    setValue('isNotInsured', e.target.checked);
    resetField('insuranceProvider');
    resetField('insuranceMemberID');
    resetField('medicareEnabled');
    resetField('medicaidEnabled');
    trigger('isNotInsured');
  };

  const checkMedicareBoxChangeHandler = (e: CheckboxChangeEvent) => {
    setValue('medicareEnabled', e.target.checked);
    trigger('medicareEnabled');
  };

  const checkMedicaidBoxChangeHandler = (e: CheckboxChangeEvent) => {
    setValue('medicaidEnabled', e.target.checked);
    trigger('medicaidEnabled');
  };

  const carriers = useQuery({
    queryKey: ['carriers'],
    queryFn: async () => {
      const response = await getPayorsCarriersList();
      setInsuranceCarriers(response);
      return response.reduce<SelectOptions>((result, carrier) => {
        // Filter out any bad data in the stagig API
        if (
          carrier.id === undefined ||
          carrier.id === null ||
          carrier.id === '' ||
          carrier.display_name === undefined ||
          carrier.display_name === null ||
          carrier.display_name === ''
        )
          return result;

        result.push({
          key: carrier.id,
          value: carrier.id,
          label: carrier.display_name,
        });
        return result;
      }, []);
    },
  });

  const mutation = useMutation({
    mutationFn: async (data: Schema) => {
      if (data.isNotInsured) {
        if (isSponsored) {
          return {
            is_insurance_coverage_active: false,
            is_sponsored_for_membership: true,
          };
        }
        return {
          is_insurance_coverage_active: false,
          is_sponsored_for_membership: false,
        };
      }

      if (data.medicareEnabled || data.medicaidEnabled) {
        await toggleMedicareMedicaidStatus(accountId, {
          is_medicare_enrolled: data.medicareEnabled,
          is_medicaid_enrolled: data.medicaidEnabled,
        });
      }

      // Check if selected provider is in list
      const insuranceCarrier = insuranceCarriers.find(
        carrier => carrier.id === data.insuranceProvider
      );
      // If custom insurer entered don't call API
      if (!insuranceCarrier) {
        Mixpanel.track(MixpanelEvents.CUSTOM_INSURANCE_CARRIER_ENTERED, {
          'Insurer Entered': data.insuranceProvider,
        });
        // If we hit this, we're not hitting the onSuccess call
        // so we track "Submitted" here
        PatientAnalytics.track(screenName, 'Submitted', {
          source_flow: screenSourceFlow,
          insurer_entered: data.insuranceProvider,
        });
        return {
          is_insurance_coverage_active: false,
          is_sponsored_for_membership: false,
        };
      }

      return checkPatientCoverage({
        patientId,
        insuranceCarrierId: data.insuranceProvider,
        memberId: data.insuranceMemberID,
      });
    },
    onSuccess: response => {
      Mixpanel.track(MixpanelEvents.FTUX_SUBMITTED_INSURANCE_INFO_SCREEN);
      // If we hit this, we shouldn't have hit the "custom insurer" version
      // of the Submitted event
      PatientAnalytics.track(screenName, 'Submitted', {
        source_flow: screenSourceFlow,
      });
      setIsInsuranceEligible(response.is_sponsored_for_membership);
      setNextStepFor('INSURANCE_ENTRY');
    },
    onError: () => {
      PatientAnalytics.track(screenName, 'Errored', {
        source_flow: screenSourceFlow,
      });
      setError('root.serverError', {
        message: t('insurance.unable_to_verify_insurance_error'),
      });
    },
  });

  const onBack = () => {
    setPreviousStepFor('INSURANCE_ENTRY');
  };

  if (carriers.isLoading) return <FullPageLoading />;
  if (!carriers.data) throw new Error('Something went wrong loading carriers');

  return (
    <form onSubmit={handleSubmit(data => mutation.mutate(data))}>
      <PageLayout
        progress={5 / 7}
        screenName={screenName}
        screenSourceFlow={screenSourceFlow}
      >
        {mutation.isLoading && (
          <div className="absolute inset-0 z-50 grid place-items-center bg-space-10 bg-opacity-70">
            <div>
              <Lottie
                className="mx-auto mb-4 w-12"
                animationData={loadingAnimation}
                loop={false}
              />
              <p className="font-bold text-white">
                {t('insurance.verifying_details_status')}
              </p>
            </div>
          </div>
        )}

        <PageLayoutContent>
          <Heading dataTestId="insuranceHeader">
            {t('insurance_header')}
          </Heading>
          <Paragraph type="note">{t('insurance_body')}</Paragraph>
          <GenericError message={formState.errors.root?.serverError?.message} />
          <Space size={16} direction="vertical" className="w-full">
            <InsuranceAutoComplete
              control={control}
              name="insuranceProvider"
              options={carriers.data}
              placeholder={t('insurance_select_plan_label')}
              disabled={watch('isNotInsured')}
            />
            <TextInput
              control={control}
              name="insuranceMemberID"
              label={t('insurance_member_id_label')}
              type="text"
              disabled={watch('isNotInsured')}
            />
            <Checkbox
              className="-mt-4"
              control={control}
              name="medicareEnabled"
              onChange={checkMedicareBoxChangeHandler}
              disabled={watch('isNotInsured')}
            >
              {t('insurance_medicare_plan')}
            </Checkbox>
            <Checkbox
              className="mt-1"
              control={control}
              name="medicaidEnabled"
              onChange={checkMedicaidBoxChangeHandler}
              disabled={watch('isNotInsured')}
            >
              {t('insurance_medicaid_plan')}
            </Checkbox>
            <Checkbox
              className="mt-1"
              control={control}
              name="isNotInsured"
              dataTestId="isNotInsured"
              onChange={checkNoInsuranceBoxChangeHandler}
            >
              {t(`insurance_dont_have`)}
            </Checkbox>
          </Space>
        </PageLayoutContent>
        <PageLayoutFooter>
          <CircleBack onClick={onBack} />
          <CircleSubmit
            valid={formState.isValid}
            loading={formState.isSubmitting}
          />
        </PageLayoutFooter>
      </PageLayout>
    </form>
  );
}
