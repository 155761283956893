import { useAuth0 } from '@auth0/auth0-react';
import { env } from 'lib/env';
import { PatientAnalytics, segmentReset } from './patientAnalytics';

export const useLogout = () => {
  const { logout: logoutWithAuth0 } = useAuth0();

  const logout = async () => {
    PatientAnalytics.track('Logout', 'Completed');

    PatientAnalytics.reset();

    // Ensures anonymous ID is reset for next user
    await segmentReset();

    logoutWithAuth0({
      logoutParams: {
        returnTo: env.VITE_APP_ORIGIN,
      },
    });
  };

  return { logout };
};
