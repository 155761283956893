import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useEffect, useState, type ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAnonymousId } from 'helpers/patientAnalytics';
import { FullPageLoading } from 'components';
import { env } from '../lib/env';

type AuthProviderProps = {
  children: ReactElement;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const navigate = useNavigate();
  const [anonymousId, setAnonymousId] = useState<string | undefined | null>(
    null
  );

  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get('email') ?? undefined;
  const signup = searchParams.get('signup') ?? undefined;
  const loginHint = email ?? undefined;
  const screenHint =
    email || (signup && signup !== 'false') ? 'signup' : undefined;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const providerConfig = {
    domain: env.VITE_AUTH0_DOMAIN || '',
    clientId: env.VITE_AUTH0_CLIENT_ID || '',
    onRedirectCallback,
    authorizationParams: {
      redirectUri: `${env.VITE_APP_ORIGIN}/callback`,
      audience: env.VITE_AUTH0_AUDIENCE || '',
      screen_hint: screenHint,
      login_hint: loginHint,
    },
  };

  useEffect(() => {
    const fetchAnonymousId = async () => {
      const id = await getAnonymousId();
      setAnonymousId(id);
    };

    fetchAnonymousId();
  }, []);

  if (anonymousId === null) return <FullPageLoading />;

  return (
    <Auth0Provider
      domain={providerConfig.domain}
      clientId={providerConfig.clientId}
      authorizationParams={{
        audience: providerConfig.authorizationParams.audience,
        redirect_uri: providerConfig.authorizationParams.redirectUri,
        screen_hint: providerConfig.authorizationParams.screen_hint,
        login_hint: providerConfig.authorizationParams.login_hint,
        analytics_anonymous_id: anonymousId,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
}
