import { useAuth0 } from '@auth0/auth0-react';
import { FullPageLoading } from 'components';
import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import {
  setAnonymousId,
  AnalyticsScreenName,
  AnalyticsSourceFlow,
  PatientAnalytics,
} from 'helpers/patientAnalytics';

type AuthRequiredBoundaryProps = {
  children: ReactElement;
};

export function AuthRequiredBoundary(props: AuthRequiredBoundaryProps) {
  const { children } = props;
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const searchParams = new URLSearchParams(window.location.search);
  const source = searchParams.get('source') ?? undefined;
  const email = searchParams.get('email') ?? undefined;

  // Force a login request if user is not authenticated
  useEffect(() => {
    const registerIdAndLogin = async () => {
      await setAnonymousId();
      await Mixpanel.track(MixpanelEvents.VIEW_AUTH0_UNIVERSAL_LOGIN, {
        source,
      });
      const screenName: AnalyticsScreenName = 'Auth0UniversalLoginScreen';
      await PatientAnalytics.track(screenName, 'Viewed', { source });

      if (email) {
        await Mixpanel.track(MixpanelEvents.FTUX_STARTED_PRE_AUTH0, {
          source,
          email,
        });
        const subflowName: AnalyticsScreenName = 'PreAuth0Flow';
        const subflowSourceFlow: AnalyticsSourceFlow = 'ftux';
        await PatientAnalytics.track(subflowName, 'Started', {
          source_flow: subflowSourceFlow,
          source,
          email,
        });
      }

      await loginWithRedirect({
        appState: {
          returnTo: '/',
        },
      });
    };

    if (!isLoading && !isAuthenticated) {
      registerIdAndLogin();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading || !isAuthenticated) return <FullPageLoading />;

  return children;
}
