import { HealthWebCaseAndCareplan } from '@galileo/core-api-client';
import { InnerSubHeading, Paragraph, SubHeading } from 'components';
import { useTranslation } from 'react-i18next';
import { CarePlan } from './CarePlan';

type CasesComponentProps = {
  cases: HealthWebCaseAndCareplan[];
};

export const Cases = (props: CasesComponentProps) => {
  const { t } = useTranslation('frontitude');
  const { cases } = props;
  const activeCases = cases.filter(c => !c.is_archived);

  let carePlanExists = false;
  activeCases.forEach(activeCase => {
    if (activeCase.careplan !== null) {
      carePlanExists = true;
    }
  });

  return (
    <div className="mt-2 flex flex-col justify-between gap-4">
      <div className="text-start">
        <p className="text-xs font-semibold uppercase tracking-wider text-charcoal-6">
          {t('home_cases_sub')}
        </p>
      </div>
      {activeCases.length > 0 ? (
        activeCases.map(healthWebCase => (
          <Case key={healthWebCase.case_id} healthWebCase={healthWebCase} />
        ))
      ) : (
        <p
          data-testid="noCases"
          className="text-sm font-normal text-charcoal-6"
        >
          {t('home_no_cases_body')}
        </p>
      )}
      {carePlanExists ?? <QuestionsComponent />}
    </div>
  );
};

type CaseComponentProps = {
  healthWebCase: HealthWebCaseAndCareplan;
};

const Case = (props: CaseComponentProps) => {
  const { t } = useTranslation('frontitude');
  const { healthWebCase } = props;
  const hasCarePlan = healthWebCase.careplan !== null;

  return (
    <div
      className={`inline-flex flex-col items-start justify-between rounded-lg border border-ivory-6 bg-white p-6 ${
        hasCarePlan ?? 'gap-6'
      }`}
      data-testid="case"
    >
      <SubHeading className="text-start text-3xl text-charcoal-12">
        {healthWebCase.name}
      </SubHeading>
      {healthWebCase.careplan ? (
        <CarePlan careplan={healthWebCase.careplan} />
      ) : (
        <span
          data-testid="noCareplan"
          className="text-left font-normal text-charcoal-6"
        >
          {t('case_pre_careplan_body')}
        </span>
      )}
    </div>
  );
};

const QuestionsComponent = () => {
  const { t } = useTranslation('frontitude');
  return (
    <div className="inline-flex flex-col items-start justify-between gap-4 rounded-lg border border-ivory-6 bg-white p-6 text-left">
      <InnerSubHeading>{t('questions_header')}</InnerSubHeading>
      <Paragraph>{t('questions_body')}</Paragraph>
    </div>
  );
};
