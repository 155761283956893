import { format } from 'date-fns';
import { Appointment } from 'models';
import { NavigateFunction } from 'react-router-dom';
import { ReactComponent as GalileoVideoVisitLogo } from 'assets/galileo_video_visit_logo.svg';
import { ReactComponent as StethoscopeIcon } from 'assets/stethoscope.svg';
import { ReactComponent as StethoscopeIconMobile } from 'assets/stethoscope_mobile.svg';
import { useTranslation } from 'react-i18next';
import userAgent from 'helpers/userAgent';
import { Divider } from 'antd';

type AppointmentsComponentProps = {
  appointment: Appointment | undefined;
  navigate: NavigateFunction;
};

export const Appointments = (props: AppointmentsComponentProps) => {
  const { t } = useTranslation('frontitude');
  const { appointment, navigate } = props;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="text-start">
        <p className="text-xs font-semibold uppercase tracking-wider text-charcoal-6">
          {t('home_appointments_sub')}
        </p>
      </div>
      {appointment
        ? UpcomingAppointment({ appointment })
        : NoUpcomingAppointment({ navigate })}
    </div>
  );
};

type NoUpcomingAppointmentProps = {
  navigate: NavigateFunction;
};

const NoUpcomingAppointment = (props: NoUpcomingAppointmentProps) => {
  const { t } = useTranslation('frontitude');
  const { navigate } = props;

  return userAgent.isMobile() ? (
    <NoUpcomingAppointmentMobile navigate={navigate} />
  ) : (
    <div className="flex flex-row items-center justify-between rounded-lg border border-ivory-6 bg-white p-4">
      <div className="inline-flex items-center justify-start gap-4 py-2">
        <StethoscopeIcon />
        <div className="flex w-apptHead flex-col items-start justify-start gap-1">
          <div className="font-semibold">{t('book_visit_banner_header')}</div>
          <div className="text-left text-sm text-charcoal-6">
            {t('book_visit_banner_body')}
          </div>
        </div>
      </div>
      <button
        type="button"
        data-testid="scheduleButton"
        className="bg-space-10 px-4 py-1.5 text-white"
        style={{ borderRadius: '18px' }}
        onClick={() => navigate('/intakes/search')}
      >
        {t('book_visit_banner_button')}
      </button>
    </div>
  );
};

const NoUpcomingAppointmentMobile = (props: NoUpcomingAppointmentProps) => {
  const { t } = useTranslation('frontitude');
  const { navigate } = props;

  return (
    <div className="flex flex-col items-center justify-between gap-4 rounded-lg border border-ivory-6 bg-white p-4">
      <div className="inline-flex flex-col items-center justify-between gap-4 p-2">
        <StethoscopeIconMobile />
        <div className="flex w-72 flex-col items-center justify-center gap-1 text-center">
          <span className="font-semibold">{t('book_visit_banner_header')}</span>
          <div className="text-sm text-charcoal-6">
            {t('book_visit_banner_body')}
          </div>
        </div>
      </div>
      <button
        type="button"
        data-testid="scheduleButton"
        className="bg-space-10 px-4 py-1.5 text-white"
        style={{ borderRadius: '18px' }}
        onClick={() => navigate('/intakes/search')}
      >
        {t('book_visit_banner_button')}
      </button>
    </div>
  );
};

type UpcomingAppointmentProps = {
  appointment: Appointment;
};

export const UpcomingAppointment = (props: UpcomingAppointmentProps) => {
  const { t } = useTranslation('frontitude');
  const { appointment } = props;
  const appointmentDateTime = new Date(appointment?.datetime);
  const appointmentDate = format(appointmentDateTime, 'MMMM dd, yyyy');
  const appointmentTime = format(appointmentDateTime, 'h:mmaaa');

  return userAgent.isMobile() ? (
    <UpcomingAppointmentMobile appointment={appointment} />
  ) : (
    <div className="inline-flex items-center justify-between rounded-lg border border-ivory-6 bg-white p-4">
      <div className="flex items-center justify-start gap-4">
        <div className="relative h-12 w-12">
          <GalileoVideoVisitLogo />
        </div>
        <div>
          <span className="text-lg font-semibold leading-5 text-zinc-800">
            {appointmentDate}
          </span>
          <span className="text-lg font-normal leading-5 text-zinc-800">
            {' '}
            {appointmentTime}
          </span>
        </div>
      </div>
      <div className="flex items-start justify-center gap-4">
        <div className="flex items-start justify-start gap-2">
          <div className="text-blue-600 text-sm font-normal leading-4">
            <a
              target="_blank"
              rel="noreferrer"
              href={appointment?.confirmationPage}
              className="text-sm no-underline"
            >
              {t('reschedule_appt_link')}
            </a>
          </div>
        </div>
        <div className="flex items-start justify-start gap-2">
          <div className="text-blue-600 text-sm font-normal leading-4">
            <a
              target="_blank"
              rel="noreferrer"
              href={appointment?.confirmationPage}
              className="text-sm no-underline"
            >
              {t('cancel_appt_link')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UpcomingAppointmentMobile = (props: UpcomingAppointmentProps) => {
  const { t } = useTranslation('frontitude');
  const { appointment } = props;
  const appointmentDateTime = new Date(appointment?.datetime);
  const appointmentDate = format(appointmentDateTime, 'MMMM dd, yyyy');
  const appointmentTime = format(appointmentDateTime, 'h:mmaaa');

  return (
    <div className="inline-flex flex-col items-center justify-between gap-4 rounded-lg border border-ivory-6 bg-white p-4">
      <div className="flex flex-col items-center justify-center gap-4">
        <div className="relative h-12 w-12">
          <GalileoVideoVisitLogo />
        </div>
        <div className="flex flex-col gap-0">
          <span className="text-lg font-semibold leading-5 text-zinc-800">
            {appointmentDate}
          </span>
          <span className="text-lg font-normal leading-5 text-zinc-800">
            {' '}
            {appointmentTime}
          </span>
        </div>
      </div>
      <Divider style={{ margin: 0 }} />
      <div className="flex items-start justify-center gap-4">
        <div className="flex items-start justify-start gap-2">
          <div className="text-blue-600 text-sm font-normal leading-4">
            <a
              target="_blank"
              rel="noreferrer"
              href={appointment?.confirmationPage}
              className="text-sm no-underline"
            >
              {t('reschedule_appt_link')}
            </a>
          </div>
        </div>
        <div className="flex items-start justify-start gap-2">
          <div className="text-blue-600 text-sm font-normal leading-4">
            <a
              target="_blank"
              rel="noreferrer"
              href={appointment?.confirmationPage}
              className="text-sm no-underline"
            >
              {t('cancel_appt_link')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
