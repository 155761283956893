import { ReactComponent as PhonesImage } from 'assets/galileo_phones.svg';
import { ReactComponent as PhonesImageMobile } from 'assets/galileo_phones_mobile.svg';
import { sendDownloadLinkService } from 'features/NewUserOnboarding/services';
import userAgent from 'helpers/userAgent';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const DownloadAppBanner = () => {
  return (
    <div>
      {userAgent.isMobile() ? (
        <DownloadAppBannerMobile />
      ) : (
        <DownloadAppBannerDesktop />
      )}
    </div>
  );
};

const DownloadAppBannerDesktop = () => {
  const { t } = useTranslation('frontitude');

  const [submitBtnMessage, setSubmitBtnMessage] = useState(
    t('app_banner_button')
  );
  const [disabled, setDisabled] = useState(false);

  const {
    patientAccount: {
      mobile_country_code: countryCode,
      mobile_phone_number: phoneNumber,
      language_id: language,
    },
  } = usePatient();

  const handleSubmit = async () => {
    try {
      await sendDownloadLinkService(countryCode, phoneNumber, language);
      setSubmitBtnMessage(t('download_app_banner.message_sent_confirmation'));
      setDisabled(true);
    } catch (error) {
      setSubmitBtnMessage(t('download_app_banner.try_again_button'));
    }
  };

  return (
    <div className="flex flex-row items-center justify-between rounded-lg border border-ivory-6 bg-mint-2 pb-0 pl-4 pr-4 pt-0">
      <div className="inline-flex items-center justify-start gap-4 pt-4">
        <PhonesImage className="self-end" />
        <div className="flex w-downloadText flex-col items-center justify-start gap-1">
          <div className="font-semibold">{t('app_banner_header')}</div>
          <div className="text-sm text-charcoal-6">{t('app_banner_body')}</div>
        </div>
        <button
          type="button"
          data-testid="textLinkButton"
          className={`px-4 py-1.5 ${
            disabled
              ? 'text-space-10border border-space-10 bg-transparent'
              : 'bg-space-10 text-white '
          }`}
          style={{ borderRadius: '18px' }}
          onClick={handleSubmit}
          disabled={disabled}
        >
          {submitBtnMessage}
        </button>
      </div>
    </div>
  );
};

const DownloadAppBannerMobile = () => {
  const { t } = useTranslation('frontitude');
  const appDownloadLink = userAgent.isAndroid()
    ? 'https://play.google.com/store/apps/details?id=com.galileohealth.android&hl=en_US&gl=US&pli=1'
    : 'https://apps.apple.com/us/app/galileo-health-medical-care/id1322601067';
  return (
    <div className="flex flex-col items-center justify-between rounded-lg border border-ivory-6 bg-mint-2 p-4">
      <div className="inline-flex flex-col items-center justify-start gap-4">
        <PhonesImageMobile />
        <div className="flex w-60 flex-col items-center justify-center gap-1 text-center">
          <div className="font-semibold">{t('app_banner_header')}</div>
          <div className="text-sm text-charcoal-6">{t('app_banner_body')}</div>
        </div>
        <a href={appDownloadLink} target="_blank" rel="noreferrer">
          <button
            type="button"
            data-testid="downloadAppButton"
            className="bg-space-10 px-4 py-1.5 text-white"
            style={{ borderRadius: '18px' }}
          >
            {t('download_app_banner.download_app_button')}
          </button>
        </a>
      </div>
    </div>
  );
};
