import { Divider, Skeleton } from 'antd';
import { Lab, Prescription, Todo, TodoType } from 'models/todo';
import { ReactComponent as GalileoArrowButton } from 'assets/galileo_arrow_button.svg';
import { ReactComponent as GalileoLabIcon } from 'assets/galileo_lab_icon.svg';
import { ReactComponent as GalileoPrescriptionIcon } from 'assets/galileo_prescription_icon.svg';
import { InnerSubHeading } from 'components';
import { useTranslation } from 'react-i18next';
import { useHomePageStore } from './store';

type PatientTodosProps = {
  todos: Todo[];
  isLoading: boolean;
};

export const PatientTodos = (props: PatientTodosProps) => {
  const { t } = useTranslation('frontitude');
  const { todos, isLoading } = props;

  return (
    <div
      className="inline-flex w-full flex-col items-start justify-center"
      data-testid="task"
    >
      <InnerSubHeading>{t('tasks.header')}</InnerSubHeading>
      <Skeleton data-testid="skeleton" loading={isLoading}>
        <div className="w-full py-6">
          {todos && todos.length > 0 ? (
            todos.map((todo, index) => (
              <div key={todo.todo_id}>
                <PatientTodo todo={todo} />
                {index !== todos.length - 1 && <Divider />}
              </div>
            ))
          ) : (
            <span
              data-testid="noTodos"
              className="text-sm font-normal text-charcoal-6"
            >
              {t('task_errors.no_tasks_assigned_message')}
            </span>
          )}
        </div>
      </Skeleton>
    </div>
  );
};

type PatientTodoProps = {
  todo: Todo;
};

const PatientTodo = (props: PatientTodoProps) => {
  const { t } = useTranslation('frontitude');
  const { todo } = props;

  switch (todo.todo_type) {
    case TodoType.Lab:
      return <LabTodo lab={todo.order_details as Lab[]} />;
    case TodoType.Prescription:
      return (
        <PrescriptionTodo prescription={todo.order_details as Prescription[]} />
      );
    default:
      return <div>{t('task_errors.unknown_todo_type_error')}</div>;
  }
};

type LabTodoProps = {
  lab: Lab[] | [];
};

const LabTodo = (props: LabTodoProps) => {
  const { t } = useTranslation('frontitude');
  const { lab } = props;
  const [setShowTaskModal, setLabTask] = useHomePageStore(state => [
    state.setShowTaskModal,
    state.setLabTask,
  ]);

  const onLoadLabModal = () => {
    setShowTaskModal(true);
    setLabTask(lab);
  };

  return (
    <div
      data-testid="labTodo"
      className="inline-flex w-full flex-row items-center justify-between gap-2"
    >
      <div className="inline-flex flex-col items-center justify-between gap-2 justify-self-start">
        <div className="inline-flex shrink grow basis-0 flex-row items-start justify-start gap-2">
          <div className="inline-flex flex-row items-start justify-start gap-2">
            <GalileoLabIcon />
          </div>
          <div className="text-xl1 font-semibold leading-6 text-zinc-800">
            {t('lab_tests_sub')}
          </div>
        </div>
        <div className="inline-flex items-center justify-between self-stretch pl-8">
          <div className="h-5 shrink grow basis-0 text-left text-base font-normal leading-tight text-charcoal-6">
            {t('quest_diagnostics')}
          </div>
        </div>
      </div>
      <div className="inline-flex flex-col items-center justify-center gap-2 justify-self-end">
        <button
          type="button"
          data-testid="labTodoButton"
          className="border-none focus:outline-none"
          onClick={onLoadLabModal}
        >
          <GalileoArrowButton />
        </button>
      </div>
    </div>
  );
};

type PrescriptionTodoProps = {
  prescription: Prescription[] | [];
};

const PrescriptionTodo = (props: PrescriptionTodoProps) => {
  const { t } = useTranslation('frontitude');
  const { prescription } = props;
  const [setShowTaskModal, setPrescriptionTask] = useHomePageStore(state => [
    state.setShowTaskModal,
    state.setPrescriptionTask,
  ]);

  const onLoadPrescriptionModal = () => {
    setShowTaskModal(true);
    setPrescriptionTask(prescription);
  };

  return (
    <div
      data-testid="prescriptionTodo"
      className="inline-flex w-full flex-row items-center justify-between gap-2"
    >
      <div className="inline-flex w-full flex-col items-center justify-between gap-2 justify-self-start">
        <div className="inline-flex w-full shrink grow basis-0 flex-row items-start justify-start gap-2">
          <div className="inline-flex flex-row items-start justify-start gap-2">
            <GalileoPrescriptionIcon />
          </div>
          <div className="text-xl1 font-semibold leading-6 text-zinc-800">
            {t('prescriptions_sub')}
          </div>
        </div>
        <div className="inline-flex items-center justify-between self-stretch pl-8">
          <div className="h-5 shrink grow basis-0 text-left text-base font-normal leading-tight text-charcoal-6">
            {prescription[0].pharmacy_store_name}
          </div>
        </div>
      </div>
      <div className="inline-flex flex-col items-center justify-center gap-2 justify-self-end">
        <button
          type="button"
          data-testid="prescriptionTodoButton"
          className="border-none focus:outline-none"
          onClick={onLoadPrescriptionModal}
        >
          <GalileoArrowButton />
        </button>
      </div>
    </div>
  );
};
